import { useState } from 'react';

import dynamic from 'next/dynamic';

import { useScreenSize } from '~/src/common/hooks/useScreenSize';
import { useFeatureFlag } from '~/src/common/services/FeatureFlag/client';

import FamilyCard from '../FamilyCard/FamilyCard';
import MiniMenu from '../MiniMenu/MiniMenu';
import { useNavigationTracking } from '../tracking';
import type * as T from '../types';

import * as NewStyles from './layout';
import * as OldStyles from './layout-old';

// Chargé dynamiquement pour alléger la taille de la réponse initiale
const MegaMenu = dynamic(async () => import('../MegaMenu/MegaMenu'), { ssr: false });

type Props = {
  active: boolean;
  family: T.Family;
  vertical?: boolean;
  onClick: () => void;
};

const FamilyItem = ({ family, active, vertical, onClick }: Props) => {
  const isFamiliesVisibleOnMobile = useFeatureFlag('show-families-home-mobile');
  const { isLargeScreen } = useScreenSize();
  const [isMounted, setMounted] = useState(false);
  const { onClickFamilyCard } = useNavigationTracking();

  // Combinaison du click et du tracking
  const handleClick = () => {
    onClickFamilyCard(family.id, active ? 'close' : 'open');
    onClick();
  };

  const S = isFamiliesVisibleOnMobile ? { ...NewStyles } : { ...OldStyles };

  return (
    <S.Container aria-selected={active} $vertical={vertical} className={`menu-family-${family.id}`}>
      {vertical ? (
        <S.FamilyName>{family.name}</S.FamilyName>
      ) : (
        <FamilyCard family={family} onClick={handleClick} />
      )}
      {isMounted ? null : <MiniMenu isHidden={!vertical} family={family} />}
      {isLargeScreen && <MegaMenu family={family} onMount={setMounted} />}
    </S.Container>
  );
};

export default FamilyItem;
