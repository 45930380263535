import styled from 'styled-components';

import CommonIcon from '../Icon';

export const ButtonContent = styled.span<{ $isLoading?: boolean }>`
  opacity: ${({ $isLoading }) => ($isLoading ? 0 : 1)};
  white-space: nowrap;
`;

export const Button = styled.button<{ $isLoading?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  height: 42px;
  min-width: 42px;
  margin: 0;

  font-weight: 500;
  line-height: 33px;
  border-radius: ${({ theme }) => theme.layout.borderRadius};
  border: 1px solid ${({ theme }) => theme.palette.common.DISABLED};
  background: ${({ theme }) => theme.palette.common.WHITE};
  box-shadow: none;
  cursor: pointer;

  &,
  * {
    outline: none;
  }

  &:hover,
  &:active {
    border-color: inherit;
    box-shadow: none;
  }

  &&:disabled {
    cursor: not-allowed;
    ${({ theme, $isLoading }) =>
      !$isLoading
        ? `
        background: ${theme.palette.common.DISABLED};
        color: ${theme.palette.common.WHITE};
        border-color: ${theme.palette.common.DISABLED};
      `
        : ''};
  }
`;

export const LoaderContainer = styled.span`
  position: absolute;
  inset: 0;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${({ theme }) => theme.palette.common.TRANSPARENT};
`;

export const LoaderInnerContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 10px;

  > svg {
    height: 100%;
    margin: 0 4px;
  }
`;

export const InnerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 8px;
  color: inherit;

  > span[role='img']:only-child {
    margin: 0;
  }

  > :first-child {
    margin-left: 16px;
  }

  > :last-child {
    margin-right: 16px;
  }
`;

export const Icon = styled(CommonIcon)<{ $isLoading?: boolean }>`
  opacity: ${({ $isLoading }) => ($isLoading ? 0 : 1)};
  text-decoration: none !important;
`;
