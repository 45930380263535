export default {
  'title': 'Choisissez votre créneau',
  'no-timeslot-available': "Aucun créneau n'est disponible actuellement",
  'closing-event': {
    'title': 'Fermeture exceptionnelle ',
    'date': 'du %{startDate} au %{endDate} inclus',
    'date-one': 'le %{date}',
  },
  'my-address': 'Mon adresse',
  'collect': 'Retrait - %{name}',
  'confirmation-modal': {
    'title': 'Voulez-vous passer une nouvelle commande ?',
    'slot-description':
      'En changeant de créneau, vous n’ajouterez plus des articles à votre commande en cours. Une autre commande sera créée. Vous conservez les articles actuellement dans votre panier.',
    'address-description': 'En changeant d’adresse, un nouveau créneau vous sera attribué.',
    'button': 'Passer une autre commande',
  },
  'toaster-auto-select-timeslot': {
    success: 'Créneau expiré. Nouveau créneau sélectionné : <span>%{slot}</span>',
    fail: 'Créneau expiré. Aucun prochain créneau disponible.',
  },
};
