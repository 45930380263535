import { omit } from '~/src/common/utils/object';

export interface ImageTypeParams {
  c?: 'scale' | 'fit' | 'mfit' | 'fill' | 'limit' | 'pad' | 'thumb' | 'crop';
  dpr?: 'auto' | '2.0';
  fl?: 'progressive';
  g?: 'auto' | 'center' | 'north';
  f?: 'auto' | 'png' | 'jpg';
  q?: number | 'auto';
  x?: number;
  y?: number;
  h?: number | 'ih';
  w?: number | 'iw';
  ar?: number;
}

export const defaultParams: ImageTypeParams = {
  c: 'fill',
  dpr: 'auto',
  g: 'north',
  f: 'auto',
  q: 70,
};

export const ImageParams = {
  mainBanner: { ...defaultParams, q: 80, c: 'fill', h: 400, w: 2560, g: 'center' },
  banner: { ...defaultParams, c: 'fit', h: 450, w: 2560, g: 'center' },
  headerCard: { ...defaultParams, h: 400, q: 90, g: 'center' },
  modalBanner: { ...defaultParams, g: 'center' },
  marketingModalBanner: { ...defaultParams, g: 'center', w: 800, c: 'fit', h: 320, q: 90 },
  deliveryModalShopImage: { ...defaultParams, w: 600, c: 'fit', h: 320, q: 90 },
  basket: { ...defaultParams, h: 75, w: 75 },
  productListItem: { ...defaultParams, h: 72, w: 56, q: 90 },
  list: { ...defaultParams, h: 75, w: 75 },
  categories: { ...defaultParams, w: 200 },
  listingCard: { f: 'auto', g: 'north', q: 80, w: 290 },
  productPage: { ...defaultParams, w: 750 },
  productThumbnail: { ...defaultParams, q: 80, w: 100 },
  zoomedIn: { ...defaultParams, w: 1100 },
  subcategories: { ...defaultParams, q: 100, w: 300 },
  landing: { f: 'auto', q: 80, w: 1920 },
  miniCardList: { ...defaultParams, h: 56, w: 41, q: 90 },
  recipeItem: { ...defaultParams, h: 96, w: 56, q: 90 },
  productLabels: { ...omit(defaultParams, 'f'), h: 48, w: 48, q: 90 },
  productBigLabels: { ...omit(defaultParams, 'f'), h: 80, w: 80, q: 90 },
  recipeIngredient: { ...defaultParams, h: 160, w: 128, q: 80 },
  recipeIngredientModalHeader: { ...defaultParams, h: 140, w: 130, q: 80 },
  familyCategory: { ...defaultParams, c: 'fill', w: 128, h: 128 },
  familyHeader: { ...defaultParams, c: 'fill', w: 480, h: 160 },
  familyAside: { ...defaultParams, c: 'fill', w: 580, h: 944 },
  familyIcon: { ...defaultParams, c: 'fit', h: 128 },
  pdrList: { ...defaultParams, c: 'fill', ar: 2.4, w: 370, dpr: '2.0', g: 'center' },
} satisfies Record<string, ImageTypeParams>;

export enum ImageFormatIds {
  Square = 'square',
  CardRatio = 'card_ratio',
}

export type ImageCropTransform = {
  type: 'crop';
  w: number;
  h: number;
  x: number;
  y: number;
};

export type ImageFormat = {
  id: string; // ImageFormatIds
  ratio: number;
  transforms: ImageCropTransform[];
};

export interface Image {
  id: string;
  url: string;
  name: string;
  alt?: string;
  formats?: ImageFormat[];
}
