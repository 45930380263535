import { fetch } from '~/src/common/services/Networking';

import { getUpdatedServiceURL } from './utils';

// Petit wrapper autour du fetch isomorphique afin de
// s'assurer de toujours prefix les appels vers l'api
export const fetchAPI: typeof fetch = args => {
  // Le `/api` est déjà inclus dans le path des routes
  const baseServiceUrl = process.env.NEXT_PUBLIC_ECOM_API_URL.replace(/\/api$/, '');
  const updatedServiceUrl = getUpdatedServiceURL(baseServiceUrl);
  return fetch({
    ...args,
    forwardHost: true,
    forwardCookies: true,
    url: `${updatedServiceUrl}${args.url}`,
  });
};
