import '~/src/common/services/I18n/I18n';
import 'tippy.js/dist/tippy.css';
import React, { useMemo, useRef } from 'react';

import NiceModal from '@ebay/nice-modal-react';
import { Hydrate, QueryClient, QueryClientProvider, hashQueryKey } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import App, { AppContext, AppProps } from 'next/app';
import { StyleSheetManager, ThemeProvider } from 'styled-components';

import BodyScripts from '~/src/common/components/BodyScripts';
import ErrorBoundary from '~/src/common/components/ErrorBoundary';
import GlobalStyles from '~/src/common/components/GlobalStyles';
import FontIcons from '~/src/common/components/GlobalStyles/FontIcons';
import Fonts from '~/src/common/components/GlobalStyles/Fonts';
import useSmartBanner from '~/src/common/services/Appsflyer/useSmartBanner';
import { DataDog } from '~/src/common/services/Datadog';
import { FeatureFlagProvider } from '~/src/common/services/FeatureFlag/client';
import ModalsManager from '~/src/common/services/ModalsManager';
import { RouterContext } from '~/src/common/services/Router/context';
import useGtmPageTracking from '~/src/common/services/TagManager/hooks/useGtmPageTracking';
import { ToasterContainer } from '~/src/common/services/Toaster';
import useAmplitudePageTracking from '~/src/common/services/Tracker/hooks/useAmplitudePageTracking';
import { UserAgentContext } from '~/src/common/services/UserAgent';
import { useWebVitals } from '~/src/common/services/vitals';
import { mainTheme } from '~/src/common/themes/main';
import CatalogLayout from '~/src/screens/App/CatalogLayout';
import InitGate from '~/src/screens/App/InitGate';

const IGNORED_STYLED_PROPS = ['isLoading', 'fixed'];

const MyApp = ({ Component, pageProps }: Omit<AppProps, 'router'>) => {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            queryKeyHashFn: queryKey => {
              // Pour les fetch de panier, on utilise systématiquement la même clé
              if (queryKey[0] === '/api/cart') return hashQueryKey([queryKey[0]]);
              // Pour les autres requêtes, on conserve le comportement par défaut
              return hashQueryKey(queryKey);
            },
            refetchOnWindowFocus: false,
            retry: false,
            staleTime: 30_000,
            cacheTime: 300_000,
          },
        },
      }),
  );

  useWebVitals();
  useSmartBanner();
  useGtmPageTracking();
  useAmplitudePageTracking();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment  -- auto-ignored when updating eslint
  const Layout = Component.Layout ?? CatalogLayout;

  const fallbackEmptyFeatureFlags = {};

  // On utilise des ref pour les valeurs pouvant devenir undefined lors de l'utilisation
  // de la navigation 100% CSR, pour laquelle les pageProps de NextJS ne sont pas remontées.
  // Dans le cas ou elles ne sont pas définies, on fallback alors sur la derniere valeur connue.
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access  -- auto-ignored when updating eslint
  const uaRef = useRef(pageProps.parsedUA);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access  -- auto-ignored when updating eslint
  const ffRef = useRef(pageProps.featureFlags);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access  -- auto-ignored when updating eslint
  uaRef.current = pageProps.parsedUA ?? uaRef.current;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access  -- auto-ignored when updating eslint
  ffRef.current = pageProps.featureFlags ?? ffRef.current ?? fallbackEmptyFeatureFlags;
  const routerContextValues = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- auto-ignored when updating eslint
    () => ({ isFirstView: pageProps.isFirstView }),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- auto-ignored when updating eslint
    [pageProps.isFirstView],
  );

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access  -- auto-ignored when updating eslint
  if (ffRef.current['mcom-monitoring-perfs'] && typeof window === 'object') {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access  -- auto-ignored when updating eslint
    DataDog.setup(ffRef.current['mcom-monitoring-perfs']);
  }

  return (
    <ErrorBoundary>
      <Fonts />
      <FontIcons />
      <QueryClientProvider client={queryClient}>
        <Hydrate
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access -- auto-ignored when updating eslint
          state={pageProps.dehydratedState}>
          <ReactQueryDevtools
            initialIsOpen={false}
            position="bottom-right"
            toggleButtonProps={{
              style: {
                bottom: '45px',
              },
            }}
          />
          {/* FIXME: Investiguer pourquoi $isLoading (transientProp) sur le composant Button casse le layout
                https://keplr.atlassian.net/browse/SC-9526
              */}
          <StyleSheetManager
            shouldForwardProp={propName => !IGNORED_STYLED_PROPS.includes(propName)}>
            <ThemeProvider theme={mainTheme}>
              <FeatureFlagProvider
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- auto-ignored when updating eslint
                initialFlagValues={ffRef.current}>
                <UserAgentContext.Provider
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- auto-ignored when updating eslint
                  value={uaRef.current}>
                  <RouterContext.Provider value={routerContextValues}>
                    <GlobalStyles />
                    <ToasterContainer />
                    <NiceModal.Provider>
                      <ModalsManager />
                      <BodyScripts />
                      <InitGate>
                        <Layout {...pageProps}>
                          <Component {...pageProps} />
                        </Layout>
                      </InitGate>
                    </NiceModal.Provider>
                  </RouterContext.Provider>
                </UserAgentContext.Provider>
              </FeatureFlagProvider>
            </ThemeProvider>
          </StyleSheetManager>
        </Hydrate>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

// Désactivation de la génération de fichiers statiques
// Plus d'infos : https://github.com/keplr-team/mon-marche/pull/1241
MyApp.getInitialProps = (context: AppContext) => App.getInitialProps(context);

export default MyApp;
