import { isString } from './guards';

// Un objet contenant n'importe quoi
type Params = Nullable<Record<string, unknown>>;

// Une fausse URL utilisée pour parser les string lors de l'utilisation
// de `new URL()`. Cette base doit impérativement commencer par un scheme
// reconnu par les browsers et avoir un domaine avec une syntaxe valide.
// Sans ça, le parsing va throw (différement selon la plateforme et l'os)
const BASE_URL = 'https://keplr.mm/';

/**
 * Tente de parser la valeur passée en entrée comme une URL
 * Retour un objet `URL` en cas de succès, `null` sinon
 */
export const tryParseUrl = (value: unknown, base?: string) => {
  if (!isString(value)) return null;
  try {
    return new URL(value, base);
  } catch {
    return null;
  }
};

/**
 * Extrait le pathname depuis une URL au format string.
 * Comme le domaine n'est pas utilisé, le paramètre `x:/` est
 * fourni à `URL()` pour gérer les valeurs d'url sans domaine.
 */
export const getPathname = (value: unknown) => {
  if (!isString(value)) return null;
  const url = tryParseUrl(value, BASE_URL);
  return url ? url.pathname : null;
};

/**
 * Extrait les search params depuis une URL au format string.
 * Comme le domaine n'est pas utilisé, le paramètre `x://` est
 * fourni à `URL()` pour gérer les valeurs d'url sans domaine.
 */
export const getSearchParams = (value: unknown) => {
  if (!isString(value)) return null;
  const url = tryParseUrl(value, BASE_URL);
  return url ? url.searchParams : null;
};

/**
 * Nettoie l'objet en entrée afin de l'utiliser en tant que QueryString
 * Les valeurs nulles seront supprimées, le reste converti en string
 */
export const cleanSearchParams = (params: Params) => {
  return Object.entries({ ...params }).flatMap(([key, val]): [key: string, val: string][] => {
    if (Array.isArray(val)) return val.filter(v => v != null).map(v => [key, String(v)]);
    return val == null ? [] : [[key, String(val)]];
  });
};

/**
 * Converti l'objet passé en entrée en une query string.
 * L'implémentation est naive et ne supporte pas d'objet profond.
 */
export const getFormattedQueryString = (params: Params) => {
  const cleaned = cleanSearchParams(params);
  return new URLSearchParams(cleaned).toString();
};

/**
 * A partir d'une URL, cette fonction va rajouter de nouveaux query parmas
 * > Si l'url contient des params, ils seront conservés, et mergés
 */
export const appendQueryParams = (url: string, params?: Params) => {
  const res = new URL(url);
  const cleaned = cleanSearchParams(params);
  cleaned.forEach(([k, v]) => res.searchParams.append(k, v));
  return res.toString();
};

/**
 * Extrait les utms des paramètres de l'uri pour l'encoder sans ses paramètres
 */
export const formatRedirectUri = (uri: string) => {
  // Division de l'URI en base et paramètres
  const [baseUri, queryParams] = uri.split('?');
  const queryParamsArray = queryParams ? queryParams.split('&') : [];

  // Extraction des paramètres à encoder (qui ne sont pas des paramètres utm)
  const paramsToEncode = queryParamsArray.filter(param => !param.startsWith('utm_')).join('&');

  // Encodage de la partie sans les paramètres utm
  const encodedBaseUrl = encodeURIComponent(
    `${baseUri}${paramsToEncode ? `?${paramsToEncode}` : ''}`,
  );

  // Récupération des paramètres utm
  const utmParams = queryParamsArray.filter(param => param.startsWith('utm_')).join('&');

  // Concaténation de la partie encodée avec les paramètres utm
  const formatedUrl = `${encodedBaseUrl}${utmParams ? `&${utmParams}` : ''}`;

  return formatedUrl;
};

export function isSamePage(a: string, b: string) {
  let urlA: URL | null = null;
  let urlB: URL | null = null;

  try {
    urlA = new URL(a);
  } catch {
    // noop
  }

  try {
    urlB = new URL(b);
  } catch {
    // noop
  }

  if (urlA != null && urlB == null) urlB = new URL(b, urlA.origin);
  if (urlB != null && urlA == null) urlA = new URL(b, urlB.origin);
  if (urlA == null && urlB == null) {
    urlA = new URL(a, BASE_URL);
    urlB = new URL(a, BASE_URL);
  }

  const removePrefix = (value: string) => value.replace(/^http(s)?:\/\/(www\.)?/, '');

  return (
    urlA != null &&
    urlB != null &&
    removePrefix(urlA.origin) === removePrefix(urlB.origin) &&
    urlA.pathname === urlB.pathname
  );
}
