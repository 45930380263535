import dayjs from '~/src/common/services/Date';
import I18n from '~/src/common/services/I18n';
import constants from '~/src/constants';

import * as S from '../layout';

interface Props {
  onClickVersion?: () => void;
  isCheckout: boolean;
  marginBottom: number;
}

const LAUNCHING_YEAR = 2019;

const VersionSection = ({ onClickVersion, isCheckout, marginBottom }: Props) => {
  const currentYear = parseInt(dayjs().format('YYYY'), 10);
  const copyrightYear =
    LAUNCHING_YEAR !== currentYear ? `${LAUNCHING_YEAR}-${currentYear}` : currentYear;

  return (
    <S.VersionContainer $isCheckout={isCheckout} $marginBottom={marginBottom}>
      <span>
        {I18n.t('footer.copyright', { domainUrl: constants.baseDomainUrl, copyrightYear })}
      </span>
      {onClickVersion ? (
        <S.VersionLink onClick={onClickVersion}>
          {I18n.t('footer.version')} {process.env.NEXT_PUBLIC_RELEASE_NAME}
        </S.VersionLink>
      ) : (
        <span>
          {I18n.t('footer.version')} {process.env.NEXT_PUBLIC_RELEASE_NAME}
        </span>
      )}
    </S.VersionContainer>
  );
};

export default VersionSection;
