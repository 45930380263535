export const getSubcategoryAnchor = (item: { id: string }) => {
  return item.id;
};

export const getCategoryPageLink = (item: { slug: string }) => {
  return `/categorie/${item.slug}`;
};

export const getSubcategoryAnchorLink = (cat: { slug: string }, sub: { id: string }) => {
  return `${getCategoryPageLink(cat)}#${getSubcategoryAnchor(sub)}`;
};
