import { DeliverySlot, DeliveryZone } from '~/src/common/typings/cart';

export const isFreeShippingSlot = (slot: DeliverySlot, deliveryType?: DeliveryZone['type']) =>
  'activeDeliveryPrice' in slot && slot.activeDeliveryPrice === 0 && deliveryType === 'delivery';

export const isGreenTimeslot = (slot: DeliverySlot) => {
  return slot.tag === 'green';
};

export const isAvailableGreenTimeslot = (slot: DeliverySlot) => {
  return isGreenTimeslot(slot) && !slot.isFull && !slot.isExcluded;
};
