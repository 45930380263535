export default {
  menu: {
    unknown: {
      title: 'Nos rayons',
    },
  },
  empty: {
    small: {
      text: 'Aucun article disponible dans la catégorie pour votre date de livraison.',
    },
    large: {
      title: 'Cette catégorie est vide',
      text: 'Pour cette catégorie, nous ne disposons plus de produits pour votre date de livraison.',
      btn: "Revenir à l'accueil",
    },
  },
  error: {
    small: {
      text: 'Une erreur est survenue lors du chargement.',
      btn: 'Rafraîchir la liste',
    },
    large: {
      text: 'Une erreur est survenue lors du chargement.',
      btn: 'Recharger la catégorie',
    },
  },
};
