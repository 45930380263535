import { MouseEventHandler } from 'react';

import Spinner from '~/src/common/components/Spinner';
import dayjs from '~/src/common/services/Date';
import { useFeatureFlag } from '~/src/common/services/FeatureFlag';
import { useNiceModal } from '~/src/common/services/ModalsManager';
import Tracker, { DELIVERY_ORDER_STATUS } from '~/src/common/services/Tracker';
import { daysDiff } from '~/src/common/utils/date';
import {
  CurrentOrders200ItemsItem,
  CurrentOrders200ItemsItemDelayDuration,
} from '~/src/queries/api-ecom/generated/api-ecom.schemas';
import { isDelivery } from '~/src/screens/App/CatalogLayout/utils';
import ConfettisLayout from '~/src/screens/Home/InProgressOrdersBanner/ConfettisLayout';
import DeliveryCompleteOrderSlotInfos from '~/src/screens/Home/InProgressOrdersBanner/DeliveryCompleteOrderSlotInfos';
import DeliverySlotInfos from '~/src/screens/Home/InProgressOrdersBanner/DeliverySlotInfos';
import { getOrderStatusLabel, getOrderStatusCompletion } from '~/src/screens/Home/utils';

import * as S from './layout';
import { useEstimatedTimeSlot } from './useEstimatedTimeSlot.hook';

interface Props {
  ongoingOrder: CurrentOrders200ItemsItem;
  delay?: CurrentOrders200ItemsItemDelayDuration;
  isJaugeProgressionDisplayed: boolean;
}

const InProgressOrderBanner = ({ ongoingOrder, delay, isJaugeProgressionDisplayed }: Props) => {
  const isCompleteOrderActivated = useFeatureFlag('complete-order');

  const isCompleteOrder = isCompleteOrderActivated && !!ongoingOrder.completableUntil;
  const { state, delivery, id: orderId } = ongoingOrder;
  const estimatedTimeSlot = useEstimatedTimeSlot(orderId, delivery);
  const modal = useNiceModal('delivery-tracking-drawer');

  const { deliveryZone } = delivery;

  const from = estimatedTimeSlot?.[0] ?? delivery.timeSlot.from;
  const to = estimatedTimeSlot?.[1] ?? delivery.timeSlot.to;

  const handleBannerClick: MouseEventHandler<HTMLAnchorElement> = event => {
    if (isDelivery(delivery)) {
      event.preventDefault();

      void modal.show();
      return;
    }

    const fromDayJs = dayjs(from);
    const startTime = fromDayJs.format('HH[h]mm');
    const endTime = dayjs(to).format('HH[h]mm');

    Tracker.sendEvent('click order tracking panel', {
      'shipping type': deliveryZone?.type === 'delivery' ? 'delivery' : 'pickup',
      'pickup mode': deliveryZone?.pickupType,
      'shipping slot': `${startTime}-${endTime}`,
      'shipping slot days': daysDiff(from),
      'cart id': orderId,
      'order status': DELIVERY_ORDER_STATUS[state],
    });
  };

  const deliverySlotInfos = isCompleteOrder ? (
    <DeliveryCompleteOrderSlotInfos completableUntil={ongoingOrder.completableUntil as number} />
  ) : (
    <DeliverySlotInfos delay={delay} delivery={delivery} estimatedTimeSlot={estimatedTimeSlot} />
  );

  return (
    <S.Container $isJaugeProgressionDisplayed={isJaugeProgressionDisplayed}>
      <S.StyledLink href={{ pathname: `/account/orders/${orderId}` }} onClick={handleBannerClick}>
        <S.Content>
          <ConfettisLayout>
            <Spinner>
              <S.SpinnerContent>{getOrderStatusCompletion(state)}</S.SpinnerContent>
            </Spinner>
            <S.InProgressOrdersTitle>{getOrderStatusLabel(state)}</S.InProgressOrdersTitle>
            <S.DesktopDeliverySlotInfos>{deliverySlotInfos}</S.DesktopDeliverySlotInfos>
          </ConfettisLayout>
          <S.MobileDeliverySlotInfos>{deliverySlotInfos}</S.MobileDeliverySlotInfos>
        </S.Content>
      </S.StyledLink>
    </S.Container>
  );
};

export default InProgressOrderBanner;
