import { css } from 'styled-components';

import { getMediaQuery } from '~/src/common/utils/style';

export const NAV_HEIGHT = 48;
export const TREEVIEW_WIDTH = 256;
export const TREEVIEW_MARGIN = 32;

export const breakpoints = {
  XXXXL: 1920,
  XXXL: 1456,
  XXL: 1358,
  XL: 1248,
  L: 1080,
  M: 832,
  S: 624,
  XS: 360,
};

export const media = Object.fromEntries(
  Object.entries(breakpoints).map(([breakpointName, breakpointValue]) => {
    const fn: typeof css = (...args: Parameters<typeof css>) => css`
      @media ${getMediaQuery(breakpointValue, null)} {
        ${css(...args)};
      }
    `;

    return [breakpointName, fn];
  }),
);

export const zIndex = {
  categoriesMenuHeaderBlur: 9,
  marketingHeadersChevron: 10,
  categoriesMenu: 14,
  familiesMenu: 14,
  footerLegal: 14,
  inProgressOrderBanner: 14,
  deliveryProgressBanner: 15,
  navigationHeader: 15,
  sideModalHeader: 16,
  sideModalCloseButton: 17,
  marketingHeadersSliderNav: 50,
  checkoutLeftContainer: 50,
  checkoutRightContainer: 50,
  checkoutHeader: 75,
  landingHeader: 80,
  checkoutSummaryButton: 90,
  sideModalOverlay: 90,
  sideModalInnerContainer: 95,
  cartScreen: 96,
  modalOverlay: 99,
  modalV2Overlay: 99,
  modalV2Container: 100,
  modalContainer: 100,
  modalInnerContainer: 100,
  modalV2InnerContainer: 100,
  formSelectPopover: 100,
  googlePlacesAutocompleteLoader: 100,
  landingMainLoader: 100,
  googleMapInfoWindow: 100,
  googleMapInfoWindowActive: 101,
  searchProductScreen: 110,
  sideModalContainer: 125,
  cartProductsActionsSidePanel: 127,
  expiredCompleteOrderModal: 150,
  updatedProductsPanel: 127,
  fullPageModalContainer: 130,
  signinModal: 140,
  slotConfirmationModal: 140,
  cgvModal: 145,
  actionSheet: 146,
  errorModal: 150,
  zoomedInImageModal: 150,
};
