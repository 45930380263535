/**
 * Arrondi une valuer en utilisant la précision spécifiée
 * Avec precision = 0 : Arrondi à l'unité
 * Avec precision = 1 : Arrondi au dixieme
 * Avec precision = 2 : Arrondi au centieme
 * Avec precision = -1 : Arrondi à la dizaine
 * Avec precision = -2 : Arrondi à la centaine
 * ....
 */
export const round = (value: number, precision = 0) => {
  const scale = 10 ** precision;
  return Math.round(value * scale) / scale;
};

/**
 * Force la valeur fournie a être dans l'intervalle spécifié
 */
export const clamp = (value: number, min: number, max: number) => {
  return Math.max(min, Math.min(max, value));
};
