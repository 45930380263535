import Sticker from '~/src/common/components/Sticker';
import { useFeatureFlag } from '~/src/common/services/FeatureFlag/client';
import { ImageParams } from '~/src/common/typings/image';
import { getImageUrl } from '~/src/common/utils/cloudinary';

import { useNavigationTracking } from '../tracking';
import type * as T from '../types';

import * as NewStyles from './layout';
import * as OldStyles from './layout-old';

type Props = {
  isHidden?: boolean;
  family: T.Family;
};

const MiniMenu = ({ family, isHidden }: Props) => {
  const isFamiliesVisibleOnMobile = useFeatureFlag('show-families-home-mobile');
  const { categories } = family;
  const { onClickCategoryName } = useNavigationTracking().small;

  const S = isFamiliesVisibleOnMobile ? { ...NewStyles } : { ...OldStyles };

  return (
    <S.Container $isHidden={isHidden}>
      {categories.map(({ id, name, slug, label, image }) => (
        <S.Item key={id} href={`/categorie/${slug}`} onClick={() => onClickCategoryName(id)}>
          <S.Image src={getImageUrl(image, ImageParams.familyCategory)} alt={name} />
          <S.Name>{name}</S.Name>
          {label && (
            <Sticker bg={label.bg} fg={label.fg}>
              {label.text}
            </Sticker>
          )}
          <S.Arrow name="Chevron-right" />
        </S.Item>
      ))}
    </S.Container>
  );
};

export default MiniMenu;
