import { TP_WIDGETS } from '~/src/common/services/Trustpilot/utils';

const TrustscoreWidget = () => (
  <div
    className="trustpilot-widget"
    data-locale="fr-FR"
    data-template-id={TP_WIDGETS.mini}
    data-businessunit-id={process.env.NEXT_PUBLIC_TRUSTPILOT_BUSINESSUNIT_ID}
    data-style-height="120px"
    data-style-width="225px">
    <a
      href="https://fr.trustpilot.com/review/mon-marche.fr"
      target="_blank"
      rel="noopener noreferrer">
      Trustpilot
    </a>
  </div>
);

export default TrustscoreWidget;
