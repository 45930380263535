import React from 'react';

import useAppInit from '~/src/screens/App/useAppInit';

type Props = {
  children: React.ReactNode;
};

const InitGate = ({ children }: Props) => {
  useAppInit();

  return children;
};

export default InitGate;
