export const DataDog = {
  setup: async (samplingRate: number) => {
    DataDog.setup = () => Promise.resolve();
    const { datadogRum } = await import('@datadog/browser-rum-slim');

    datadogRum.init({
      applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
      clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.eu',
      service: 'ecom',
      env: process.env.NEXT_PUBLIC_APP_TIER,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: process.env.NEXT_PUBLIC_APP_VERSION,
      sessionSampleRate: samplingRate,
      sessionReplaySampleRate: samplingRate,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
    datadogRum.startSessionReplayRecording();
  },
};
