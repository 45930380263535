import { useEffect } from 'react';

import useAriaKeyboardNavigation from '~/src/common/hooks/useAriaKeyboardNavigation';
import useDelayedValue from '~/src/common/hooks/useDelayedValue';
import Tracker from '~/src/common/services/Tracker';
import { useGetSearchSuggestions } from '~/src/queries/api-ecom/generated/api-ecom';

import { DEBOUNCE_QUERY_DELAY } from '../../../constants';

import * as S from './layout';
import SuggestionsCategory from './SuggestionsCategory';
import SuggestionsKeyword from './SuggestionsKeyword';

type Props = {
  query: string;
};

const Suggestions = ({ query }: Props) => {
  // Délai de la query pour ne pas spam l'API
  const delayed = useDelayedValue(query, DEBOUNCE_QUERY_DELAY);
  const { data, isFetching } = useGetSearchSuggestions(
    { query: delayed }, // La recherche de l'utilisateur, envoyée en querystring
    { query: { keepPreviousData: true } }, // Config pour éviter le clignotement
  );

  // On vérifie si on a des résultats
  const hasResults = !!data?.keywords.length || !!data?.categories.length;

  // Amplitude: Suivi du nombre de suggestions affichées
  useEffect(() => {
    if (isFetching || data == null) return;
    Tracker.sendEvent('view suggestions results', {
      'user input': delayed,
      'Number of suggestions': data.keywords.length,
      'Number of subcategories': data.categories.length,
      'Number of results': data.keywords.length + data.categories.length,
    });
  }, [data, delayed, isFetching]);

  // Gestion de la navigation au clavier
  const ref = useAriaKeyboardNavigation<HTMLDivElement>('a');

  return hasResults ? (
    <S.Container>
      <S.Entries ref={ref}>
        {data.keywords.map(keyword => (
          <SuggestionsKeyword key={keyword} keyword={keyword} query={query} />
        ))}
        {data.categories.map(category => (
          <SuggestionsCategory key={category.id} category={category} query={query} />
        ))}
      </S.Entries>
    </S.Container>
  ) : null;
};

export default Suggestions;
