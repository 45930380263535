import { useEffect } from 'react';

import Script from 'next/script';

import { useScreenSize } from '~/src/common/hooks/useScreenSize';
import { useStable } from '~/src/common/hooks/useStable';
import { useFeatureFlag } from '~/src/common/services/FeatureFlag';
import Tracker from '~/src/common/services/Tracker';
import ZendeskService from '~/src/common/services/Zendesk';

const Zendesk = () => {
  const { isLargeScreen } = useScreenSize();
  const isCopernicEnable = useFeatureFlag('copernic-chatbot');

  // On affiche le widget zendesk sur desktop uniquement
  useEffect(() => {
    if (isLargeScreen) {
      ZendeskService.show();
    } else {
      ZendeskService.hide();
    }
  }, [isLargeScreen]);

  const handleOnOpen = useStable(() => {
    Tracker.sendEvent('click open the chat');
  });

  const handleOnClose = useStable(() => {
    // On cache le widget zendesk à sa fermeture sur mobile uniquement
    if (!isLargeScreen) ZendeskService.hide();

    Tracker.sendEvent('click close the chat');
  });

  // Gestion spécifique au moment de l'ouverture/fermeture du widget
  useEffect(() => {
    ZendeskService.onOpen(handleOnOpen);

    ZendeskService.onClose(handleOnClose);
  }, [handleOnOpen, handleOnClose]);

  return (
    <>
      {isCopernicEnable ? (
        <>
          <div id="assistant-copernic"></div>
          {/* eslint-disable-next-line react/no-unknown-property */}
          <script src="https://chat.copernic.co/widget.js" api-key="NMM0ADJzBnfU6FWV"></script>
        </>
      ) : (
        <Script
          id="ze-snippet"
          strategy="afterInteractive"
          src={`https://static.zdassets.com/ekr/snippet.js?key=${process.env.NEXT_PUBLIC_ZENDESK_API_KEY}`}
        />
      )}
    </>
  );
};

export default Zendesk;
