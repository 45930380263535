export default {
  'title': '<b>COMMANDE EN COURS</b> (%{count} article)',
  'title_plural': '<b>COMMANDE EN COURS</b> (%{count} articles)',
  'sub-title': `Ajouter des articles jusqu’à <b>%{completableUntil}</b>`,
  'timer': {
    'sub-title': 'Il vous reste <b>%{timeLeft}</b> pour ajouter des articles',
    'end-error': {
      title: 'Il n’est plus possible d’ajouter des produits',
      message:
        'Nous avons déjà commencé à préparer votre commande, il n’est plus possible de la modifier.',
    },
  },
};
