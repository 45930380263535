import styled from 'styled-components';

import { media } from '~/src/styles-constants';

export const CATALOG_SIDE_PADDING = 11;
export const CATALOG_SIDE_PADDING_V2 = 56;

export const CatalogLayoutContainer = styled.div<{ $isProgressOrdersBannerDisplayed: boolean }>`
  width: 100%;
  position: relative;
  padding-left: calc(env(safe-area-inset-left, 0px) + ${CATALOG_SIDE_PADDING}px);
  padding-right: calc(env(safe-area-inset-right, 0px) + ${CATALOG_SIDE_PADDING}px);

  ${media.XL<{ $isProgressOrdersBannerDisplayed: boolean }>`
    max-width: 100%;
    padding-left: calc(env(safe-area-inset-left, 0px) + ${CATALOG_SIDE_PADDING_V2}px);
    padding-right: calc(env(safe-area-inset-right, 0px) + ${CATALOG_SIDE_PADDING_V2}px);
  `}
`;
