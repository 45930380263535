import { transparentize } from '~/src/common/utils/style';

export type Color = keyof typeof colors;

const BLACK = '#373636';
const WHITE = '#ffffff';

const colors = {
  ACTION_DARKER: '#565555',
  BLACK_PURE: '#000000',
  BLACK,
  BLACK_15: transparentize(BLACK, 0.15),
  BLACK_30: transparentize(BLACK, 0.3),
  BLACK_45: transparentize(BLACK, 0.45),
  BLACK_60: transparentize(BLACK, 0.6),
  PRIMARY: '#00664f',
  PRIMARY_HOVER: '#008264',
  LIGHT_GREEN: '#D6E7E3',
  XLIGHT_GREEN: '#F8FAFA',
  ERRORS: '#E87D42',
  PITAYA: '#df4661',
  BACKGROUND_1: '#f5f0e9',
  BACKGROUND_2: WHITE,
  BACKGROUND_3: '#d7d7d7',
  BACKGROUND_4: '#efefef',
  PLACEHOLDER: transparentize('#d7d7d7', 0.4),
  TEXTS: BLACK,
  DISABLED: '#a8a8ab',
  FRESHNESS: '#7FAFDC',
  INFORMATION: '#8FD6BD',
  PROMOTION: '#F0DC23',
  PROMOTION_LIGHT: '#FDF9DC',
  WHITE,
  WHITE80: transparentize(WHITE, 0.8),
  WHITE50: transparentize(WHITE, 0.5),
  WHITE20: transparentize(WHITE, 0.2),
  TRANSPARENT: 'transparent',
  PINK: '#F8C1B8',
  POSITIVE_LIGHT: '#FCF0F2',
  NEGATIVE_LIGHT: '#FDF5F0',
  TEAL: '#00664F',
  CREAM: '#F5F0E9',
  DARK_LABEL: '#173e36',
} as const;

export default colors;
