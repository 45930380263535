import assetsConstants from '~/src/assets-constants';
import { defaultParams, ImageTypeParams, Image, ImageFormatIds } from '~/src/common/typings/image';
import { omit } from '~/src/common/utils/object';

const cloudinaryRegex =
  /^https?:\/\/res.cloudinary.com\/keplr\/image\/upload\/(?:v\d+\/)?(production|staging|development)\/(.+)$/;
const urlInHTMLRegex = /<img[^>]+src="(http[s]?:\/\/[^">]+)/g;

const stringifyParams = (params: ImageTypeParams) =>
  Object.entries(params)
    .map(([key, value]) => `${key}_${value as string | number}`)
    .join(',');

/**
 * Replace cloudinary url by img url by environnement
 * @param url string
 */
export const replaceCloudinaryUrlByIod = (url: string) => {
  const cloudinaryRegexResult = cloudinaryRegex.exec(url);

  if (cloudinaryRegexResult == null) return url;

  // If cloudinary url, transform into iod
  let subdomain = '';
  const env = cloudinaryRegexResult[1];
  const filename = cloudinaryRegexResult[2];

  if (env === 'development') subdomain = 'dev.';

  if (env === 'staging') subdomain = 'staging.';

  return `https://iod.${subdomain}keplrstatic.com/api/${filename}`;
};

export const applyTransformsToIodUrl = (url: string, transformations: string) =>
  url.replace(/(.+\.keplrstatic.com\/api\/)(.+)/, `$1${transformations}$2`);

/**
 * Get iod Image url by given url and params
 * @param imageUrl string
 * @param params ImageTypeParams
 */
export const getImageUrl = (imageUrl: string, params: ImageTypeParams) => {
  const url = replaceCloudinaryUrlByIod(imageUrl);
  const stringifiedParams = stringifyParams(params);

  return applyTransformsToIodUrl(url, stringifiedParams === '' ? '' : `${stringifiedParams}/`);
};

/**
 * Get iod Image url by given name and params
 * @param imageName string
 * @param params ImageTypeParams
 */
export const getIodImageUrl = (imageName: string, params: ImageTypeParams) => {
  const env = process.env.NEXT_PUBLIC_APP_TIER;
  let baseUrl = assetsConstants.IOD_BASE_URL_DEV;

  if (env === 'staging') baseUrl = assetsConstants.IOD_BASE_URL_STAGING;

  if (env === 'production') baseUrl = assetsConstants.IOD_BASE_URL_PROD;

  return getImageUrl(`${baseUrl}${assetsConstants.IOD_IMG_ROOTPATH}${imageName}`, params);
};

export const getTransformations = (
  image: Image,
  formatId?: ImageFormatIds | null,
  imageParams: ImageTypeParams = {},
) => {
  const format = (image.formats || []).find(f => f.id === formatId);
  let transformations = '';
  const transformation: ImageTypeParams = {};
  let secondTransformation = { ...imageParams };

  // Récupère les transformations venant du format de l'image
  if (format?.transforms) {
    if (format.transforms.length > 0) {
      format.transforms.forEach(({ type, x, y, w, h }) => {
        if (type === 'crop') {
          transformation.x = x;

          transformation.y = y;

          transformation.w = w;

          transformation.h = h;

          transformation.c = 'crop';
        }
      });
    } else if (format.ratio) {
      transformation.ar = format.ratio;

      transformation.c = 'crop';
    }
  }

  // Applique la 1ere transformation
  if (Object.keys(transformation).length > 0) {
    // Si gravity avec ratio, on l'applique a la 1ere transformation
    if (secondTransformation.g) {
      if (transformation.ar) {
        transformation.g = secondTransformation.g;
      }

      secondTransformation = omit(secondTransformation, 'g');
    }

    transformations = `${stringifyParams(transformation)}/`;
  }

  // Applique la 2nd transformation
  if (Object.keys(secondTransformation).length > 0) {
    transformations += `${stringifyParams(secondTransformation)}/`;
  }

  return transformations;
};

/**
 * Get iod Image url by given image, format and params
 * @param Image Image | undefined
 * @param formatId ImageFormatIds | null | undefined,
 * @param imageParams ImageTypeParams
 */
export const getImageUrlFromFormat = (
  image?: Image,
  formatId?: ImageFormatIds | null,
  imageParams: ImageTypeParams = {},
) => {
  if (!image) return '';
  let { url } = { ...image };

  // If cloudinary url, transform into iod
  url = replaceCloudinaryUrlByIod(url);

  // Apply transforms
  const transformations = getTransformations(image, formatId, imageParams);

  return applyTransformsToIodUrl(url, transformations);
};

export const replaceInHTMLContent = (htmlContent: string) =>
  htmlContent.replace(urlInHTMLRegex, (match: string, capture: string) =>
    match.replace(capture, getImageUrl(capture, defaultParams)),
  );
