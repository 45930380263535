export default {
  'welcome': 'Bienvenue %{name}',
  'disconnect': 'Me déconnecter',
  'title': 'Mon compte',
  'menu': {
    'orders': 'Mes commandes',
    'benefits': 'Mes avantages',
    'settings': 'Mes paramètres',
    'faq': 'FAQ',
    'customer-service': 'Service client',
  },
  'mobile-menu': {
    'last-order': 'Ma dernière commande',
  },
  'customer-service-modal': {
    title: 'Service clients',
    chat: 'Chat',
    phone: 'Téléphone (+33 1 48 90 13 23)',
    email: 'Email',
    subtitle: 'Contactez-nous  7J/7 de 09h à 22h',
    form: 'Formulaire de contact',
  },
  'update-order-modal': {
    'title': 'Modifier ma commande',
    'update-timeslot': 'Modifier mon créneau',
    'add-products': 'Ajouter des produits',
    'cancel-order': 'Annuler ma commande',
    'subtitle': "Votre commande reste modifiable jusqu'à ce que nous entamions sa préparation.",
    'dialog': {
      title: 'Voulez-vous vraiment modifier votre commande ?',
      content:
        'La préparation de votre commande sera annulée et vous ne serez pas débité.<br><br>Les produits de votre commande seront conservés dans votre panier selon disponibilité et vos codes de réductions seront toujours valides.',
      footer:
        "En modifiant votre commande, la disponibilité du créneau de livraison, des produits, des prix et promotions n'est plus garantie. Si vous avez un autre panier en cours de constitution, il sera remplacé par votre panier à modifier.",
      toaster: {
        title: 'Vous pouvez modifier votre commande',
        content: 'Attention, vous devez la valider à nouveau une fois votre modification terminée',
      },
      error: {
        title: 'Délai dépassé',
        content:
          "Un préparateur a pris en charge votre commande, vous ne pouvez plus l'annuler ou la modifier.",
      },
    },
    'complete-order-toaster': {
      title: 'Vous pouvez compléter votre commande',
      message:
        'Pour compléter votre commande, ajoutez des articles à votre panier et validez le complément.',
    },
  },
};
