import { useDelivery } from '~/src/common/hooks/useDelivery';
import { useNiceModal } from '~/src/common/services/ModalsManager';
import Tracker from '~/src/common/services/Tracker';
import { isPickup } from '~/src/common/utils/delivery';

const useDeliveryActions = () => {
  const delivery = useDelivery();
  const deliveryAddressModal = useNiceModal('delivery-address-modal');
  const deliverySelectSlotModal = useNiceModal('delivery-select-slot-modal');

  const handleDeliverySlotClick = () => {
    void deliverySelectSlotModal.show();

    Tracker.sendEvent('user navigation menu', { 'navigation text': 'shipping slot' });
  };

  const handleDeliveryAddressClick = () => {
    void deliveryAddressModal.show();

    Tracker.sendEvent('user navigation menu', { 'navigation text': 'shipping address' });
  };

  return {
    deliveryZoneName: delivery.deliveryZone?.name ?? delivery.shop?.name,
    deliveryAddress: delivery.address?.addressComponents,
    timeSlot: delivery.timeSlot,
    isPickupMode: isPickup({ zoneType: delivery.deliveryZone?.type, mode: delivery.mode }),
    handleDeliverySlotClick,
    handleDeliveryAddressClick,
  };
};

export default useDeliveryActions;
