import dayjs from '~/src/common/services/Date';
import I18n from '~/src/common/services/I18n';
import { getDelayInterval } from '~/src/common/utils/delivery';

/**
 * Returns timeslot hour label
 * Ex: 11:00, 19:00 etc.
 * @param timeslot time of the slot timestamp
 */
export const getTimeslotHourLabel = (timeslot: number | string, delay = 0) => {
  return dayjs(timeslot).add(delay, 'minutes').format('HH[:]mm');
};

/**
 * Returns timeslot start hour label with 'à' prefix
 * Ex: à 12:00, à 18:00 etc.
 * @param timeslot start time of the slot timestamp
 */
export const getTimeslotStartHourLabel = (timeslot: number) => {
  return dayjs(timeslot).format('[à ]HH[:]mm');
};

/**
 * Returns timeslot interval label
 * Ex: 18:00 - 19:00
 * @param timeslotFrom start time of the slot timestamp
 * @param timeSlotEnd end time of the slot timestamp
 */
export const getTimeslotIntervalHourLabelInfos = (
  timeslotFrom: number,
  timeSlotEnd?: number,
  delay?: { min?: number; max?: number },
) => {
  const [delayMin, delayMax] = getDelayInterval(delay?.min, delay?.max);

  if (!timeSlotEnd) {
    return `${getTimeslotHourLabel(timeslotFrom, delayMin)}`;
  }

  return `${getTimeslotHourLabel(timeslotFrom, delayMin)} - ${getTimeslotHourLabel(
    timeSlotEnd,
    delayMax,
  )}`;
};

/**
 * Returns timeslot interval label
 * Ex: - 18:00 - 19:00
 * @param timeslotFrom start time of the slot timestamp
 * @param timeSlotEnd end time of the slot timestamp
 */
export const getTimeslotIntervalHourLabel = (
  timeslotFrom: number,
  timeSlotEnd?: number,
  delay?: { min?: number; max?: number },
) => {
  return `- ${getTimeslotIntervalHourLabelInfos(timeslotFrom, timeSlotEnd, delay)}`;
};

/**
 * Returns difference in days from today
 * Ex: 1, 6, 18 etc.
 * @param timeFrom start time of the slot timestamp
 */
export const daysDiff = (timeFrom: number) => {
  const today = dayjs().set('hour', 0).set('minutes', 0);
  const fromDayJs = dayjs(timeFrom);
  return fromDayJs.diff(today, 'day');
};

/**
 * Retourne le jour de livraison au format Lun 26 ou Lun 26/04 (si withMonth = true)
 * @param rawDate date au format 'DD/MM/YYYY' ou timestamp
 * @param withMonth boolean
 */
export const getFormattedDate = (rawDate: string | number, withMonth = false) => {
  const date = typeof rawDate === 'number' ? dayjs(rawDate).format('DD/MM/YYYY') : rawDate;
  const dayJsDate = dayjs(date, 'DD/MM/YYYY');
  // Par défaut dayjs retourne le jour sous la forme 'lun.', 'mar.' etc.
  const shortDay = dayJsDate.format('ddd');
  const formattedDay = shortDay[0].toUpperCase() + shortDay.substring(1, 3);

  const dayNumber = dayJsDate.format(withMonth ? 'DD/MM' : 'DD');

  return `${formattedDay} ${dayNumber}`;
};

/**
 * Retourne le jour de livraison et choix de créneaux au format Lun 26 - 18:00 - 19:00
 * @param timeSlot avec les propriétés from et to qui sont des timestamps
 */
export const getDeliveryDateWithTimeslotInterval = (
  timeSlot: { from: number; to: number },
  withMonth = false,
  withEndSlot = true,
) => {
  const timeSlotEnd = withEndSlot ? timeSlot.to : undefined;

  return `${getFormattedDate(timeSlot.from, withMonth)} ${getTimeslotIntervalHourLabel(
    timeSlot.from,
    timeSlotEnd,
  )}`;
};

/**
 * Retourne la liste des mois correspondant aux jours passés en paramètre
 * Ex: 'Janvier - Février'
 * @param days date au format DD/MM/YYYY
 */
export const getMonthsFromDays = (days: string[]) => {
  const months = days.map(day => {
    const month = dayjs(day, 'DD/MM/YYYY').format('MMMM');
    return month[0].toUpperCase() + month.substring(1, month.length);
  });

  return [...new Set(months)].join(' - ');
};

/**
 * Retourne le timestamp de demain pour une heure et une minute données
 * @param hour
 * @param minute
 */
export const getTomorrowTimestamp = (hour = 0, minute = 0) => {
  const date = dayjs().add(1, 'd').set('h', hour).startOf('h').set('m', minute);
  return date.valueOf();
};

/**
 * Retourne la date de retour en stock au format 26/04 ou 26/04 à 18:00
 * @param date timestamp
 * @param withHour boolean
 */
export const getRestockDate = (date: number, withHour = false) => {
  const formattedDate = dayjs(date).format('DD/MM');

  if (!withHour) {
    return formattedDate;
  }

  const formattedHour = dayjs(date).format('HH[:]mm');

  return `${formattedDate} ${I18n.t('date.at')} ${formattedHour}`;
};

/**
 * Retourne true si la date est dans le passé ou si le mois et l'année sont les mêmes que ceux du jour actuel
 *
 * @param expiryYear number (ex: 2021)
 * @param expiryMonth number (from 1 to 12)
 */
export const isExpired = (expiryYear: number, expiryMonth: number) => {
  return dayjs(`${expiryYear}/${expiryMonth}`, 'YYYY/M').isBefore(dayjs(), 'month');
};

export const isToday = (day: string) => dayjs(day, 'DD/MM/YYYY').isSame(dayjs(), 'day');

/**
 * Retourne le jour et une heure au format Mar 26 - 18:00
 * @param date timestamp
 */
export const getDateWithTimeslot = (date: number) => {
  return `${getFormattedDate(date, false)} ${getTimeslotIntervalHourLabel(date)}`;
};
