export default {
  'title': 'Indiquez votre adresse',
  'sub-title':
    'Afin de garantir la disponibilité des produits, veuillez indiquer votre adresse de livraison',
  'discover-pickup-zones': 'Ou découvrez nos points de retrait',
  'have-an-account': 'Vous avez déjà un compte ?',
  'login-to-fetch-addresses1': 'Connectez-vous ',
  'login-to-fetch-addresses2': ' pour récupérer vos adresses',
  'delivery-notification': 'Livraison le <span>%{slot}</span>',
  'pickup-notification': 'Retrait le <span>%{slot}</span>',
  'prices-update-notification': 'Les prix et disponibilités des produits ont été mis à jour',
};
