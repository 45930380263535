import { useRouter } from 'next/router';

import ethBloc from '~/src/common/assets/eth-bloc.png';
import { useNiceModal } from '~/src/common/services/ModalsManager';
import { isProduction } from '~/src/env-constants';
import VersionSection from '~/src/screens/App/Footer/Sections/VersionSection';
import { sendFooterNavigationEvent } from '~/src/screens/App/Footer/utils';

import AlcoholDisclaimer from './AlcoholDisclaimer';
import * as S from './layout';
import LegalSection from './Sections/LegalSection';

const FooterLegal = ({ hasOrderStatus }: { hasOrderStatus?: boolean }) => {
  const { pathname } = useRouter();
  const isCheckout: boolean = pathname.startsWith('/checkout');
  const noDisclaimerPage = pathname.startsWith('/categorie/') || pathname.startsWith('/produit/');
  const marginBottom = hasOrderStatus ? 72 : 0;
  const { show: openSpecialOperationsModal } = useNiceModal('special-operations-modal');
  const { show: openDebugModal } = useNiceModal('debug-modal');
  const { show: openPrivacyModal } = useNiceModal('privacy-modal');
  const { show: openCookiesModal } = useNiceModal('cookies-modal');

  const showSpecialOperationsModal = () => {
    sendFooterNavigationEvent('nos offres spéciales');
    void openSpecialOperationsModal();
  };

  const showPrivacyModal = () => {
    sendFooterNavigationEvent('Gestion des données personnelles');
    void openPrivacyModal();
  };

  const showCookiesModal = () => {
    sendFooterNavigationEvent('Informations sur les cookies');
    void openCookiesModal();
  };

  return (
    <>
      <LegalSection
        onClickCGV={() => sendFooterNavigationEvent('CGV et mentions légales')}
        onClickSpecialOperations={showSpecialOperationsModal}
        onClickCookies={showCookiesModal}
        onClickPrivacy={showPrivacyModal}
      />
      {!noDisclaimerPage && (
        <div>
          {isCheckout ? (
            <S.LegalInnerContainer>
              <S.EthylotestBloc src={ethBloc} alt="information ethylotest" />
              <AlcoholDisclaimer />
            </S.LegalInnerContainer>
          ) : (
            <AlcoholDisclaimer />
          )}
        </div>
      )}
      <VersionSection
        onClickVersion={
          !isProduction
            ? () => {
                void openDebugModal();
              }
            : undefined
        }
        isCheckout={isCheckout}
        marginBottom={marginBottom}
      />
    </>
  );
};

export default FooterLegal;
