import { ImageFormatIds } from '~/src/common/typings/image';

const labelsTags: { [key: string]: string } = {
  'nutritionelle-10': 'HVE',
  'cGtoN4zTM': 'IGP',
  '2eBTgqgLaa': 'Label Rouge',
  'iKEc5S0lXJ': 'AOC',
  'J4ipSAwRTH': 'ASC',
  '2uy3P1WmdD': 'Bio',
  'ZlpnYSIIbv': 'AOP',
};

export const hiddenAttributesValues = ['non', 'aucun', 'false'];

const FRESHNESS_WARRANTY_KEY_ATTRIBUTE = 'freshnesswarranty';

export const hiddenAttributesKeys = [...Object.keys(labelsTags), FRESHNESS_WARRANTY_KEY_ATTRIBUTE];

export const IMAGE_FORMAT_ID = ImageFormatIds.Square;

export enum ProductGridArea {
  Attributes = 'attributes',
  Breadcrumbs = 'breadcrumbs',
  Details = 'infos',
  Gallery = 'slide',
  Picker = 'picker',
  PrimaryInfos = 'primary-infos',
  SecondaryInfos = 'secondary-infos',
  ShortDescription = 'short-description',

  /** @deprecated: TBD quand FF configuration-page-produit cleaned  */
  Header = 'header',
}
