import { useAnalyticsInitializer } from '~/src/common/hooks/useAnalytics';
import { useCartWithTimeslot } from '~/src/screens/App/useCartWithTimeslot';
import useReferralModal from '~/src/screens/App/useReferralModal';

const useAppInit = () => {
  useAnalyticsInitializer();
  useReferralModal();
  useCartWithTimeslot();
};

export default useAppInit;
