import { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import Tracker from '~/src/common/services/Tracker';
import { Cart } from '~/src/common/typings/cart';
import { verifyAndUpdateDelivery } from '~/src/common/utils/cart/verifyAndUpdateDelivery';
import { useGetCart } from '~/src/queries/api-ecom/generated/api-ecom';

export const useCartWithTimeslot = () => {
  const { data: cart } = useGetCart<Cart>();

  // quand on recupère un nouveau panier, on vérifie si le créneau est toujours valide
  const QueryClient = useQueryClient();
  useEffect(() => {
    const timeSlotValidity = cart?.delivery?.timeSlotValidity;

    if (cart != null) {
      // Même si le créneau est valide, on actualise la property pour savoir
      // si l'user est en complétion de commande ou non
      Tracker.setUserProperties({
        'order type': cart.initialOrder ? 'additional order' : 'new order',
      });
    }

    if (cart != null && (timeSlotValidity === 'invalid' || timeSlotValidity === 'deleted')) {
      void verifyAndUpdateDelivery(QueryClient, cart);
    }
  }, [QueryClient, cart]);
};
