import { useState } from 'react';

import { LDContext } from 'launchdarkly-js-sdk-common';
import { LDProvider, useFlags } from 'launchdarkly-react-client-sdk';

import { FeatureFlagName, FeatureFlags } from '~/src/common/services/FeatureFlag/types';
import { FLAGS, getInitialContext, setUserKey } from '~/src/common/services/FeatureFlag/utils';
import { logger } from '~/src/common/services/Logger';
import { useGetCartLight } from '~/src/queries/api-ecom/generated/api-ecom';
import { GetCartLight200 } from '~/src/queries/api-ecom/generated/api-ecom.schemas';
import { usePostAuthMeQuery } from '~/src/screens/App/queries/hooks';

let featureFlagClientRef: LDProvider | null = null;

export const FeatureFlagProvider = ({
  initialFlagValues,
  children,
}: {
  initialFlagValues: FeatureFlags;
  children: React.ReactNode;
}) => {
  const { data: user } = usePostAuthMeQuery();
  const { data: cart } = useGetCartLight<GetCartLight200>();
  const [userContext] = useState(() => getInitialContext({ user, cart, deviceKey: setUserKey() }));

  return (
    <LDProvider
      ref={client => {
        featureFlagClientRef = client;
      }}
      clientSideID={process.env.NEXT_PUBLIC_LAUNCHDARKLY_MM_CLIENT_API_KEY}
      context={userContext}
      options={{
        bootstrap: initialFlagValues,
        application: {
          id: process.env.NEXT_PUBLIC_APP_NAME,
          version: process.env.NEXT_PUBLIC_APP_VERSION,
        },
        streaming: true,
      }}
      reactOptions={{
        useCamelCaseFlagKeys: false,
      }}>
      {children}
    </LDProvider>
  );
};

export const useFeatureFlag = <T extends FeatureFlagName>(name: T, fallback?: FeatureFlags[T]) => {
  const flags = useFlags<Record<T, FeatureFlags[T]>>();

  return flags[name] ?? FLAGS[name] ?? fallback;
};

export const updateFeatureFlagContext = async (
  updater: (currentContext: LDContext) => LDContext,
) => {
  const client = featureFlagClientRef?.state?.ldClient;
  if (client) {
    const currentContext = client.getContext();

    const flagValues = await client.identify(updater(currentContext));

    return flagValues;
  }

  logger.error('LDClient not initialized when updating feature flag context');
  return {};
};
