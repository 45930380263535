export default {
  'full-slot': {
    title: 'Ce créneau est complet',
    description:
      'Ce créneau a été réservé par d’autres utilisateurs et a atteint sa limite de capacité.',
  },
  'completable-order': 'Commande en cours',
  'add-items-until': 'Ajouter des articles jusqu’à <b>%{date}</b>',
  'section': {
    all: {
      title: 'Tous les créneaux',
    },
    green: {
      title: 'Le plus éco-responsable',
      dialog: {
        title: 'Livraison éco-responsable',
        text: 'En choisissant ce créneau, vous aidez nos livreurs à regrouper les trajets dans votre quartier. Résultat : moins de kilomètres parcourus pour une empreinte carbone réduite. Merci pour votre geste.',
        ok: "J'ai compris !",
      },
    },
  },
};
