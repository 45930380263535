export default {
  'initial': {
    delivery: 'Votre livraison',
    pickup: 'Votre retrait',
    remotePickup: 'Votre retrait',
  },
  'complete-order': {
    delivery: 'Rappel de votre livraison',
    pickup: 'Rappel de votre retrait',
    remotePickup: 'Rappel de votre retrait',
  },
};
