import dayjs from '~/src/common/services/Date';
import { useGetCart } from '~/src/queries/api-ecom/generated/api-ecom';

export const useDelivery = () => {
  const { data: cart } = useGetCart();
  const {
    address: cartAddress,
    timeSlot,
    mode: cartDeliveryMode,
    shop: cartShop,
  } = cart?.delivery || {};

  const cartDeliveryZone = cart?.delivery?.deliveryZone;

  const isTimeSlotValid = timeSlot != null && dayjs().isBefore(timeSlot.orderUntil);

  const isSetup = cartAddress != null && timeSlot != null;

  return {
    isSetup,
    address: cartAddress,
    mode: cartDeliveryMode,
    deliveryZone: cartDeliveryZone,
    shop: cartShop,
    timeSlot,
    isTimeSlotValid,
  };
};
