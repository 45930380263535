import type { Category, Family, Navigation, RecipeCategory, SubCategory } from './types';

// Les différents "chemins" possibles dans la navigation
type Path =
  | {
      kind: 'family';
      family: { index: number; item: Family };
    }
  | {
      kind: 'category';
      family: { index: number; item: Family };
      category: { index: number; item: Category };
    }
  | {
      kind: 'subcategory';
      family: { index: number; item: Family };
      category: { index: number; item: Category };
      subcategory: { index: number; item: SubCategory };
    }
  | {
      kind: 'recipecategory';
      recipecategory: { index: null; item: RecipeCategory };
    }
  | {
      kind: 'recipesubcategory';
      recipecategory: { index: null; item: RecipeCategory };
      recipesubcategory: { index: number; item: SubCategory };
    };

/**
 * Retourne le chemin correspondant à l'id ou au slug d'un élément de la navigation
 *
 * ⚠ Cette fonction est couteuse, et ne doit pas être utilisée pendant un render.
 */
export const getPath = (nav: Navigation, key: string): Path | null => {
  // Match un élément dont l'id ou le slug est égal à la `key`
  const isMatch = (item: { id?: string; slug?: string }) => {
    return item.id === key || item.slug === key;
  };

  // Si catégorie recette
  if (isMatch(nav.recipe)) {
    const rItem = nav.recipe;
    const recipecategory = { index: null, item: rItem };
    return { kind: 'recipecategory', recipecategory };
  }

  // Ou sous-catégorie recette
  for (let cIndex = 0; cIndex < nav.recipe.children.length; cIndex += 1) {
    const cItem = nav.recipe.children[cIndex];
    if (isMatch(cItem)) {
      const recipecategory = { index: null, item: nav.recipe };
      const recipesubcategory = { index: cIndex, item: cItem };
      return { kind: 'recipesubcategory', recipecategory, recipesubcategory };
    }
  }

  // Sinon, itération en profondeur, d'abord sur les familles
  for (let fIndex = 0; fIndex < nav.families.length; fIndex += 1) {
    const fItem = nav.families[fIndex];
    const family = { index: fIndex, item: fItem };
    if (isMatch(fItem)) return { kind: 'family', family };

    // Puis sur les catégories
    for (let cIndex = 0; cIndex < fItem.categories.length; cIndex += 1) {
      const cItem = fItem.categories[cIndex];
      const category = { index: cIndex, item: cItem };
      if (isMatch(cItem)) return { kind: 'category', family, category };

      // Et enfin sur les sous-catégories
      for (let sIndex = 0; sIndex < cItem.children.length; sIndex += 1) {
        const sItem = cItem.children[sIndex];
        const subcategory = { index: sIndex, item: sItem };
        if (isMatch(sItem)) return { kind: 'subcategory', family, category, subcategory };
      }
    }
  }

  // Aucune correspondance
  return null;
};

/** Retourne le chemin vers une famille à partir d'un ID */
export const getFamilyPath = (nav: Navigation, key: string) => {
  const match = getPath(nav, key);
  return match?.kind === 'family' ? match : null;
};

/** Retourne le chemin vers une catégorie à partir d'un ID ou d'un slug */
export const getCategoryPath = (nav: Navigation, key: string) => {
  const match = getPath(nav, key);
  return match?.kind === 'category' ? match : null;
};

/** Retourne le chemin vers une sous-catégorie à partir d'un ID ou d'un slug */
export const getSubCategoryPath = (nav: Navigation, key: string) => {
  const match = getPath(nav, key);
  return match?.kind === 'subcategory' ? match : null;
};

/** Retourne le chemin vers la catégorie recette à partir d'un ID */
export const getRecipeCategoryPath = (nav: Navigation, key: string) => {
  const match = getPath(nav, key);
  return match?.kind === 'recipecategory' ? match : null;
};
