import { useEffect, useMemo } from 'react';

import { useStable } from './useStable';

interface Props {
  selector: string;
  callback: MutationCallback;
  options?: MutationObserverInit;
}

// Watch for changes being made to a defined node in the DOM
export const useMutationObserver = ({ selector, callback, options }: Props): void => {
  // Pour des raisons de performances et ne pas reinstancier l'observer
  // à chaque render, on va générer une référence stable vers le callback
  const stableCallback = useStable(callback);

  // De la même maniere, on va garder une reference stable vers les options
  const serialized = JSON.stringify(options ?? {});
  const stableOptions = useMemo(() => JSON.parse(serialized) as typeof options, [serialized]);

  useEffect(() => {
    const target = document.querySelector(selector);
    const observer = new MutationObserver(stableCallback);
    if (target) observer.observe(target, stableOptions);
    return () => observer.disconnect();
  }, [selector, stableCallback, stableOptions]);
};
