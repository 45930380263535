import dayjs from '~/src/common/services/Date';
import I18n from '~/src/common/services/I18n';
import { CartDeliveryMode, CartDelivery, DeliverySlot } from '~/src/common/typings/cart';
import { groupBy, sortBy } from '~/src/common/utils/array';
import { DeliverySlots200DeliveryZonesItemDeliveryPricesItem } from '~/src/queries/api-ecom/generated/api-ecom.schemas';
import { OngoingOrderDelivery } from '~/src/typings/orders/types';

// obligé de mettre dans un fichier à part car dans le utils ca plante au build (window not defined)
export const getDeliveryIconName = (deliveryMode?: CartDeliveryMode | null) => {
  switch (deliveryMode) {
    case 'collect':
      return 'Move-walk';

    case 'drive':
      return 'Move-drive';

    default:
      return 'Move-delivery';
  }
};

export const getDeliveryLabelName = (deliveryMode?: CartDeliveryMode | null) => {
  switch (deliveryMode) {
    case 'collect':
      return 'walk';

    case 'drive':
      return 'drive';

    default:
      return 'delivery';
  }
};

export const getDeliveryZoneTypeIconName = (deliveryZoneType: string) => {
  switch (deliveryZoneType) {
    case 'pickup':
    case 'remotePickup':
      return 'shop-normal';
    case 'onSitePickup':
      return 'sur-place';
    default:
      return 'marker-normal';
  }
};

/**
 * Trie les seuils par order de panier min croissant
 * Renvoie les seuils pertinents pour l'user => ceux qui incluent le min de commande ou sont au dessus
 * Ce tri doit en théorie être effectué côté BACK
 * TODO supprimer cet util et l'intégrer directement côté back
 * @param deliveryPrices les seuils de livraison
 * @param minOrderAmount le min de commande
 */
export const getDeliveryPricesAboveMinCartAmount = (
  deliveryPrices: DeliverySlots200DeliveryZonesItemDeliveryPricesItem[],
  minOrderAmount: number,
): DeliverySlots200DeliveryZonesItemDeliveryPricesItem[] => {
  const mappedDeliveryPrices = deliveryPrices.sort(
    (priceA, priceB) => priceA.minCartNetPrice - priceB.minCartNetPrice,
  );

  const deliveryPriceUnderMinOrderAmount = [...mappedDeliveryPrices]
    ?.reverse()
    .find(dp => dp.minCartNetPrice <= minOrderAmount);
  const deliveryPricesAboveMinOrderAmount =
    mappedDeliveryPrices?.filter(dp => dp.minCartNetPrice > minOrderAmount) || [];

  return deliveryPriceUnderMinOrderAmount
    ? [deliveryPriceUnderMinOrderAmount, ...deliveryPricesAboveMinOrderAmount]
    : deliveryPricesAboveMinOrderAmount;
};

export const isTimeslotValid = (timeSlot: { orderUntil: number }) =>
  dayjs().isBefore(timeSlot.orderUntil);

type DeliveryZoneType = 'delivery' | 'pickup' | 'remotePickup' | 'onSitePickup';
type DeliveryMode = 'manual' | 'onFleet' | 'drive' | 'collect' | 'chronofresh';

export const isRemotePickup = (deliveryZoneType: DeliveryZoneType | undefined) =>
  deliveryZoneType === 'remotePickup';

export const isShopPickup = (mode: DeliveryMode | undefined) =>
  mode === 'collect' || mode === 'drive';

export const isPickup = ({
  zoneType,
  mode,
}: {
  zoneType?: DeliveryZoneType;
  mode?: DeliveryMode;
}) => isRemotePickup(zoneType) || isShopPickup(mode);

export const getSelectedAddressLabel = (delivery: OngoingOrderDelivery | CartDelivery) => {
  const isRemoteDeliveryPickup = isRemotePickup(delivery.deliveryZone?.type);
  const deliveryAddress = delivery.address.addressComponents;

  const formattedDeliveryAddress =
    deliveryAddress.streetNumber && deliveryAddress.street
      ? `${deliveryAddress.streetNumber} ${deliveryAddress.street}`
      : '';

  return isRemoteDeliveryPickup
    ? I18n.translate({
        value: 'common.collect-address',
        address: delivery.deliveryZone?.name ?? delivery.shop?.name ?? '',
      })
    : formattedDeliveryAddress;
};

export const getDelayInterval = (min = 0, max = 0) => {
  // Gère le cas d'un retard > 1h. Dans ce cas, le back nous renvoie `delay: { min: 60, max 0 }`
  // On souhaite afficher 19h30 20h30 pour un créneau de 18h30 19h30
  const delayMax = min > 0 && max === 0 ? min : max;

  return [min, delayMax];
};

export const getHighestShippingTreshold = (deliveryPrices: { minCartNetPrice: number }[]) =>
  deliveryPrices[deliveryPrices.length - 1]?.minCartNetPrice ?? 0;

// Montant restant à payer au niveau d'un créneau une fois les frais de livraison "offerts"
// Idéalement cette information devrait être calculée côté api
export const getRemainingShippingAmount = (deliveryPrices: { shippingAmount: number }[]) =>
  deliveryPrices[deliveryPrices.length - 1]?.shippingAmount ?? 0;

/**
 * It sorts slots and returns them grouped by day
 * ex: `{today: [], tomorrow: [], dayAfterTomorrow: [] ...}`
 * @param slots DeliverySlot[]
 */
export const groupSlotsByDay = (slots: DeliverySlot[]) => {
  // Sort slots
  const sortedSlots = sortBy(slots, slot => slot.from);

  // Group slots by days
  const groupedSlots = groupBy(sortedSlots, slot => dayjs(slot.from).format('DD/MM/YYYY'));

  return (
    Object.entries(groupedSlots)
      // Convert array to object
      .reduce<{ [k: string]: DeliverySlot[] }>((acc, [k, slot]) => {
        // Remove expired day
        const isDayExpired = slot.every(s => s.isExpired);

        if (!isDayExpired) {
          acc[k] = slot;
        }

        return acc;
      }, {})
  );
};
