import React from 'react';

import I18n from '~/src/common/services/I18n/I18n';
import { getFormattedDate, getTimeslotIntervalHourLabelInfos } from '~/src/common/utils/date';
import { getDeliveryIconName, getDeliveryLabelName } from '~/src/common/utils/delivery';
import {
  CurrentOrders200ItemsItem,
  CurrentOrders200ItemsItemDelayDuration,
} from '~/src/queries/api-ecom/generated/api-ecom.schemas';
import { isDelivery } from '~/src/screens/App/CatalogLayout/utils';

import * as S from './layout';

interface Props {
  delivery: CurrentOrders200ItemsItem['delivery'];
  delay?: CurrentOrders200ItemsItemDelayDuration;
  estimatedTimeSlot: [number, number] | null;
}

const DeliverySlotInfos = ({ delay, delivery, estimatedTimeSlot }: Props) => {
  const { deliveryZone } = delivery;
  const deliveryType = isDelivery(delivery)
    ? I18n.t(`common.${getDeliveryLabelName(deliveryZone?.pickupType)}`)
    : `${I18n.t('common.pickup')}`;

  const displayDelay = delay != null && estimatedTimeSlot === null;
  const from = estimatedTimeSlot?.[0] ?? delivery.timeSlot.from;
  const to = estimatedTimeSlot?.[1] ?? delivery.timeSlot.to;

  return (
    <S.InfoContainer>
      <S.DeliveryInfo>
        <S.PickupIcon
          name={getDeliveryIconName(deliveryZone?.pickupType)}
          color="PRIMARY"
          size={16}
        />
        <span>{`${deliveryType} - `}</span>
        <S.DeliveryInfoDate>{`${getFormattedDate(from)} - `}</S.DeliveryInfoDate>
        <S.DeliveryTime $hasLineThrough={displayDelay}>
          {getTimeslotIntervalHourLabelInfos(from, to)}
        </S.DeliveryTime>
        {displayDelay ? (
          <S.DeliveryTimeWithDelay>
            {getTimeslotIntervalHourLabelInfos(from, to, delay)}
          </S.DeliveryTimeWithDelay>
        ) : null}
      </S.DeliveryInfo>
    </S.InfoContainer>
  );
};

export default DeliverySlotInfos;
