export default {
  'title': 'Merci %{firstname} !',
  'subtitle': 'Confirmation de votre commande',
  'message':
    'Votre commande a bien été prise en compte, vous allez recevoir un mail récapitulatif.',
  'complete-section': {
    title: 'Vous avez oublié quelque chose ?',
    message:
      '<p>Vous avez <b>jusqu’à %{completableUntil} </b><br />pour ajouter des articles !</p>',
  },
  'ok': "J'ai compris",
  'confirmation-complete-order': {
    title: 'Votre commande est à jour !',
    message: 'Vous recevrez tous vos articles dans la même commande.',
  },
};
