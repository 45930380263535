import { useCallback, useState } from 'react';

const useToggle = (defaultToggled = false) => {
  const [toggled, setToggled] = useState(defaultToggled);

  const toggleOrSet = useCallback((newToggled?: boolean) => {
    if (newToggled != null) setToggled(newToggled);
    else setToggled(prevToggled => !prevToggled);
  }, []);

  return [toggled, toggleOrSet] as const;
};

export default useToggle;
