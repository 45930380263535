import React from 'react';

/* Problème avec styled-components https://github.com/styled-components/styled-components/issues/2227 */
import FontIconsTtf from '~/src/common/assets/fonts/icons/font-icons-mm.ttf';
import FontIconsWoff from '~/src/common/assets/fonts/icons/font-icons-mm.woff';
import FontIconsWoff2 from '~/src/common/assets/fonts/icons/font-icons-mm.woff2';

export const FONT_ICONS_URL = {
  woff2: `${FontIconsWoff2}`,
  woff: `${FontIconsWoff}`,
  ttf: `${FontIconsTtf}`,
};

const FontIcons = () => (
  <style jsx global>{`
    @font-face {
      font-family: 'font-icons-mm';
      src:
        url('${FONT_ICONS_URL.woff2}') format('woff2'),
        url('${FONT_ICONS_URL.woff}') format('woff'),
        url('${FONT_ICONS_URL.ttf}') format('truetype');
      font-weight: normal;
      font-style: normal;
      font-display: block;
    }

    [class^='icon-'],
    [class*=' icon-'] {
      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: 'font-icons-mm' !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;

      /* Better Font Rendering */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    /* This part is generated by scripts/update-font-icon.mjs */
    /* START */
    .icon-0::before {
      content: '\\e947';
    }
    .icon-01::before {
      content: '\\e924';
    }
    .icon-02::before {
      content: '\\e922';
    }
    .icon-03::before {
      content: '\\e923';
    }
    .icon-1::before {
      content: '\\e948';
    }
    .icon-10::before {
      content: '\\e97d';
    }
    .icon-11::before {
      content: '\\e97e';
    }
    .icon-12::before {
      content: '\\e97f';
    }
    .icon-13::before {
      content: '\\e980';
    }
    .icon-14::before {
      content: '\\e981';
    }
    .icon-15::before {
      content: '\\e982';
    }
    .icon-16::before {
      content: '\\e983';
    }
    .icon-17::before {
      content: '\\e984';
    }
    .icon-18::before {
      content: '\\e985';
    }
    .icon-19::before {
      content: '\\e986';
    }
    .icon-2::before {
      content: '\\e94a';
    }
    .icon-20::before {
      content: '\\e987';
    }
    .icon-21::before {
      content: '\\e988';
    }
    .icon-22::before {
      content: '\\e989';
    }
    .icon-23::before {
      content: '\\e98a';
    }
    .icon-24::before {
      content: '\\e98b';
    }
    .icon-25::before {
      content: '\\e98c';
    }
    .icon-26::before {
      content: '\\e98d';
    }
    .icon-27::before {
      content: '\\e98e';
    }
    .icon-28::before {
      content: '\\e98f';
    }
    .icon-29::before {
      content: '\\e990';
    }
    .icon-3::before {
      content: '\\e949';
    }
    .icon-30::before {
      content: '\\e991';
    }
    .icon-4::before {
      content: '\\e94e';
    }
    .icon-5::before {
      content: '\\e94d';
    }
    .icon-6::before {
      content: '\\e94b';
    }
    .icon-7::before {
      content: '\\e94c';
    }
    .icon-8::before {
      content: '\\e946';
    }
    .icon-9::before {
      content: '\\e945';
    }
    .icon-Apple-full::before {
      content: '\\e99c';
    }
    .icon-Apple-normal::before {
      content: '\\e99b';
    }
    .icon-arm::before {
      content: '\\e96a';
    }
    .icon-Arrow-back::before {
      content: '\\e974';
    }
    .icon-Arrow-corner-right::before {
      content: '\\e972';
    }
    .icon-Arrow-down::before {
      content: '\\e971';
    }
    .icon-Arrow-down-left::before {
      content: '\\e996';
    }
    .icon-Arrow-down-right::before {
      content: '\\e998';
    }
    .icon-Arrow-foreward::before {
      content: '\\e970';
    }
    .icon-Arrow-Modify::before {
      content: '\\e973';
    }
    .icon-Arrow-up::before {
      content: '\\e975';
    }
    .icon-Arrow-up-left::before {
      content: '\\e995';
    }
    .icon-Arrow-up-right::before {
      content: '\\e997';
    }
    .icon-Bac::before {
      content: '\\e96b';
    }
    .icon-barbecue::before {
      content: '\\e994';
    }
    .icon-basket-full::before {
      content: '\\e97a';
    }
    .icon-basket-heart::before {
      content: '\\e979';
    }
    .icon-basket-normal::before {
      content: '\\e901';
    }
    .icon-boisson::before {
      content: '\\e9a4';
    }
    .icon-Bulb::before {
      content: '\\e9a5';
    }
    .icon-burger-menu::before {
      content: '\\e937';
    }
    .icon-calendar::before {
      content: '\\e96c';
    }
    .icon-card-normal::before {
      content: '\\e97b';
    }
    .icon-Carot::before {
      content: '\\e99f';
    }
    .icon-cart::before {
      content: '\\e977';
    }
    .icon-Chargement::before {
      content: '\\e96d';
    }
    .icon-cheese-1::before {
      content: '\\e9a0';
    }
    .icon-Chevron-down::before {
      content: '\\e960';
    }
    .icon-Chevron-left::before {
      content: '\\e963';
    }
    .icon-Chevron-right::before {
      content: '\\e962';
    }
    .icon-Chevron-up::before {
      content: '\\e961';
    }
    .icon-clock-normal::before {
      content: '\\e926';
    }
    .icon-contact::before {
      content: '\\e959';
    }
    .icon-cooking-hat::before {
      content: '\\e967';
    }
    .icon-cooking-hat-full::before {
      content: '\\e993';
    }
    .icon-copy::before {
      content: '\\e95a';
    }
    .icon-cross::before {
      content: '\\e95b';
    }
    .icon-Disconnect::before {
      content: '\\e95c';
    }
    .icon-Door::before {
      content: '\\e95d';
    }
    .icon-edit::before {
      content: '\\e95e';
    }
    .icon-Empty::before {
      content: '\\e95f';
    }
    .icon-engagement::before {
      content: '\\e914';
    }
    .icon-euro-crossed::before {
      content: '\\e999';
    }
    .icon-euro-normal::before {
      content: '\\e99a';
    }
    .icon-Eye-off::before {
      content: '\\e968';
    }
    .icon-Eye-on::before {
      content: '\\e969';
    }
    .icon-Family::before {
      content: '\\e9a6';
    }
    .icon-Filter::before {
      content: '\\e958';
    }
    .icon-flag::before {
      content: '\\e952';
    }
    .icon-flashlight::before {
      content: '\\e953';
    }
    .icon-freshness::before {
      content: '\\e954';
    }
    .icon-geolocation::before {
      content: '\\e955';
    }
    .icon-gift::before {
      content: '\\e902';
    }
    .icon-heart-full::before {
      content: '\\e957';
    }
    .icon-heart-normal::before {
      content: '\\e956';
    }
    .icon-home-full::before {
      content: '\\e936';
    }
    .icon-home-normal::before {
      content: '\\e935';
    }
    .icon-hourglass::before {
      content: '\\e925';
    }
    .icon-House::before {
      content: '\\e9a7';
    }
    .icon-Hygiene::before {
      content: '\\e9a8';
    }
    .icon-info::before {
      content: '\\e91e';
    }
    .icon-Ingredient::before {
      content: '\\e931';
    }
    .icon-jambon-1::before {
      content: '\\e9a2';
    }
    .icon-leaf::before {
      content: '\\e9a1';
    }
    .icon-list-heart-full::before {
      content: '\\e93b';
    }
    .icon-list-heart-normal::before {
      content: '\\e939';
    }
    .icon-list-normal::before {
      content: '\\e938';
    }
    .icon-list-plus::before {
      content: '\\e93a';
    }
    .icon-logo-app::before {
      content: '\\e932';
    }
    .icon-marker-normal::before {
      content: '\\e93d';
    }
    .icon-marker-valid::before {
      content: '\\e93c';
    }
    .icon-matrice::before {
      content: '\\e992';
    }
    .icon-minus::before {
      content: '\\e96f';
    }
    .icon-morning::before {
      content: '\\e933';
    }
    .icon-Move-bike::before {
      content: '\\e93e';
    }
    .icon-Move-bus::before {
      content: '\\e944';
    }
    .icon-Move-delivery::before {
      content: '\\e941';
    }
    .icon-Move-delivery-clock::before {
      content: '\\e93f';
    }
    .icon-Move-drive::before {
      content: '\\e940';
    }
    .icon-Move-truck::before {
      content: '\\e943';
    }
    .icon-Move-walk::before {
      content: '\\e942';
    }
    .icon-night::before {
      content: '\\e934';
    }
    .icon-no-card::before {
      content: '\\e97c';
    }
    .icon-Notifications::before {
      content: '\\e9a3';
    }
    .icon-oven::before {
      content: '\\e966';
    }
    .icon-padlock-normal::before {
      content: '\\e951';
    }
    .icon-padlock-secure::before {
      content: '\\e94f';
    }
    .icon-padlock-valid::before {
      content: '\\e950';
    }
    .icon-Play-small::before {
      content: '\\e927';
    }
    .icon-plus::before {
      content: '\\e96e';
    }
    .icon-poisson::before {
      content: '\\e9a9';
    }
    .icon-prepare-1::before {
      content: '\\e928';
    }
    .icon-prepare-2::before {
      content: '\\e929';
    }
    .icon-prepare-3::before {
      content: '\\e92a';
    }
    .icon-pressure-cooker::before {
      content: '\\e964';
    }
    .icon-price::before {
      content: '\\e92b';
    }
    .icon-print::before {
      content: '\\e92c';
    }
    .icon-profile-full::before {
      content: '\\e92e';
    }
    .icon-profile-normal::before {
      content: '\\e92d';
    }
    .icon-profile-sign-in::before {
      content: '\\e92f';
    }
    .icon-promotion::before {
      content: '\\e913';
    }
    .icon-promotion-1::before {
      content: '\\e921';
    }
    .icon-promotion-2::before {
      content: '\\e91f';
    }
    .icon-promotion-3::before {
      content: '\\e920';
    }
    .icon-question::before {
      content: '\\e91d';
    }
    .icon-Refresh::before {
      content: '\\e90c';
    }
    .icon-Replace::before {
      content: '\\e90d';
    }
    .icon-saucepan::before {
      content: '\\e965';
    }
    .icon-search-full::before {
      content: '\\e917';
    }
    .icon-search-normal::before {
      content: '\\e918';
    }
    .icon-select::before {
      content: '\\e978';
    }
    .icon-selection::before {
      content: '\\e915';
    }
    .icon-shop-categories::before {
      content: '\\e91a';
    }
    .icon-shop-normal::before {
      content: '\\e919';
    }
    .icon-shop-return::before {
      content: '\\e91c';
    }
    .icon-shop-stand::before {
      content: '\\e91b';
    }
    .icon-smiley::before {
      content: '\\e90e';
    }
    .icon-sponsorship::before {
      content: '\\e930';
    }
    .icon-stairs::before {
      content: '\\e90f';
    }
    .icon-stall-full::before {
      content: '\\e99d';
    }
    .icon-stall-normal::before {
      content: '\\e99e';
    }
    .icon-sun::before {
      content: '\\e910';
    }
    .icon-supply::before {
      content: '\\e916';
    }
    .icon-support::before {
      content: '\\e911';
    }
    .icon-sur-place::before {
      content: '\\e912';
    }
    .icon-Switch::before {
      content: '\\e976';
    }
    .icon-Switch2::before {
      content: '\\e9aa';
    }
    .icon-thumb-up::before {
      content: '\\e900';
    }
    .icon-trash::before {
      content: '\\e904';
    }
    .icon-update::before {
      content: '\\e905';
    }
    .icon-v-hand::before {
      content: '\\e906';
    }
    .icon-valid-big::before {
      content: '\\e909';
    }
    .icon-valid-small::before {
      content: '\\e908';
    }
    .icon-Warning-circle::before {
      content: '\\e903';
    }
    .icon-warning-full::before {
      content: '\\e90b';
    }
    .icon-warning-normal::before {
      content: '\\e90a';
    }
    .icon-weight::before {
      content: '\\e907';
    }
    /* END */
  `}</style>
);

export default FontIcons;
