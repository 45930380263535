import React from 'react';

import I18n from '~/src/common/services/I18n';
import { getDateWithTimeslot } from '~/src/common/utils/date';

import * as S from './layout';

interface Props {
  completableUntil: number;
}

const DeliveryCompleteOrderSlotInfos = ({ completableUntil }: Props) => {
  return (
    <S.InfoContainer>
      <S.DeliveryInfo>
        <S.PickupIcon name={'basket-normal'} color="PRIMARY" size={16} />
        <span>
          {I18n.t('in-progress-orders-banner.complete-order.label')}
          <b> {getDateWithTimeslot(completableUntil)}</b>
        </span>
      </S.DeliveryInfo>
    </S.InfoContainer>
  );
};

export default DeliveryCompleteOrderSlotInfos;
