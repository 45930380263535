import { useEffect, useRef } from 'react';

import { useRouter } from 'next/router';

import { useAuthenticated } from '~/src/common/hooks/user/useAuthenticated';
import { useNiceModal } from '~/src/common/services/ModalsManager';
import { useCurrentOrders, useLastOrder } from '~/src/queries/api-ecom/generated/api-ecom';
import {
  hasReferralModalOpened,
  persitReferralModalOpened,
} from '~/src/screens/ReferralModal/utils';

const useReferralModal = () => {
  const { pathname } = useRouter();
  const isAuthenticated = useAuthenticated();

  const { data: lastOrderData } = useLastOrder({
    query: { enabled: isAuthenticated && !pathname.startsWith('/account/'), staleTime: Infinity },
  });

  const { data: currentOrdersData } = useCurrentOrders({
    query: { enabled: isAuthenticated && !pathname.startsWith('/account/'), staleTime: Infinity },
  });

  const lastOrder = lastOrderData?.ongoing ?? lastOrderData?.past;
  const ordersCount = currentOrdersData?.count || 0;

  const isReferalModalChecked = useRef(false);
  const lastOrderState = lastOrder?.state;

  const { show } = useNiceModal('referral-modal');

  useEffect(() => {
    if (isAuthenticated && ordersCount > 0 && !isReferalModalChecked.current) {
      if (ordersCount === 1 && lastOrderState === 'paid') {
        isReferalModalChecked.current = true;
        const hasReferralModalAlreadyOpened = hasReferralModalOpened();

        if (!hasReferralModalAlreadyOpened) {
          persitReferralModalOpened(true);

          // eslint-disable-next-line @typescript-eslint/no-floating-promises  -- auto-ignored when updating eslint
          show();
        }
      }
    }
  }, [isAuthenticated, lastOrderState, show, ordersCount]);
};

export default useReferralModal;
