import React, { Fragment } from 'react';

// eslint-disable-next-line no-restricted-imports
import toast from 'react-hot-toast';

import Icon, { IconName } from '~/src/common/components/Icon';

import * as S from './layout';

interface ToasterContent {
  message: React.ReactNode;
  actionButtonLabel?: string;
  visible: boolean;
  id: string;
  onActionButtonClick?: () => void;
}

export interface NotificationWithIcon extends ToasterContent {
  icon: IconName;
  image?: never;
}

export interface NotificationWithImage extends ToasterContent {
  icon?: never;
  image: string;
}

type NotificationProps = NotificationWithIcon | NotificationWithImage;

const Toast = ({
  icon,
  image,
  message,
  actionButtonLabel,
  visible,
  id,
  onActionButtonClick,
}: NotificationProps) => {
  const handleClick = () => {
    if (onActionButtonClick) onActionButtonClick();

    toast.dismiss(id);
  };

  const hasImage = Boolean(image);

  const ContentWrapper = hasImage ? S.ContentWrapper : Fragment;

  return (
    <S.Container
      role="presentation"
      aria-label="toaster"
      aria-hidden={!visible}
      hidden={!visible}
      $hasImage={hasImage}>
      {hasImage ? <S.Image src={image} /> : null}
      <ContentWrapper>
        <S.Content>
          {icon ? <Icon name={icon} size={24} color="PRIMARY" /> : null}
          <S.Message>{message}</S.Message>
          {!hasImage ? (
            <S.ActionButton aria-label={actionButtonLabel} onClick={handleClick} variant="link">
              {actionButtonLabel}
            </S.ActionButton>
          ) : null}
        </S.Content>
        <S.ProgressBarContainer>
          <S.ProgressBar />
        </S.ProgressBarContainer>
      </ContentWrapper>
    </S.Container>
  );
};

export default Toast;
