export default {
  'ingredients-title': 'Les Ingrédients',
  'no-ingredient': 'Aucun ingrédient nécessaire',
  'essentials-title': 'Dans votre placard',
  'steps-title': 'La préparation',
  'preparation': 'Préparation',
  'cooking': 'Cuisson',
  'duration-time': '%{duration} min',
  'portion': 'Portion',
  'portion-picker': 'portion',
  'portion-picker_plural': 'portions',
  'portions-quantities': 'Les quantités indiquées sont pour une seule personne',
  'portions-quantities_plural': 'Les quantités indiquées sont pour <b>%{count} personnes</b>',
  'max-portions-reached': 'Nombre maximum de portions atteint',
  'required-gears': 'Matériel nécessaire',
  'add-products': 'Acheter les ingrédients',
  'servings': 'Personnes',
  'products': 'Produits',
  'nb-portions': 'Nombre de portions',
  'substitute-button-label': 'Remplacer',
  'similar-products-button-label': 'Voir nos produits similaires',
  'soon-available': 'Bientôt disponible',
  'nth-promo-label': '%{quantity}ème offert',
  'ingredient-substitute-modal': {
    'title': 'Remplacer un produit',
    'button-label': 'Non, merci',
    'no-result':
      "Malheureusement, aucun produit de remplacement n'est disponible pour votre créneau de livraison.",
  },
  'add-products-success-message': '%{count} article ajouté au panier',
  'add-products-success-message_plural': '%{count} articles ajoutés au panier',
  'product-replaced': 'Le produit de la recette a été remplacé',
};
