export default {
  'title': 'Absolument tout ce qui est frais est sur <span>Mon Marché</span>',
  'title-v2': 'Les produits Grand Frais livrés chez vous 7J/7',
  'placeholder': 'Votre adresse de livraison',
  'subtitle': 'Nos produits sont <span>goûtés tous les jours</span> pour vous garantir le meilleur',
  'guest-access': 'Découvrir en tant qu’invité',
  'promo-code':
    "<span>Jusqu'à 80€ offerts</span> sur vos premières commandes avec le code <span>FRAIS</span>",
  'promo-code-v2': '<span>4x20€ OFFERTS</span> pour 40€ d’achats avec le code <span>FRAIS</span>',
  'categories': {
    title: 'Le goût au prix juste',
    subtitle: 'Les produits Grand Frais livrés chez vous gratuitement à partir de 60€',
  },
  'input': {
    'button-label': 'Découvrez nos points de retrait',
    'no-delivery':
      'Nous ne livrons pas encore cette adresse mais vous pouvez passer commande en <span>choisissant un point de retrait</span>.',
    'no-delivery-v2':
      "Nous ne livrons pas encore cette adresse <span>mais nous pouvons vous envoyer un e-mail dès qu'elle sera dans notre zone de livraison</span>.",
  },
  'choose-address': {
    title: 'Découvrez si nous livrons chez vous',
    subtitle: 'Nous livrons dans tout Paris et une partie de la 1ère couronne',
  },
  'promo-code-explanation': {
    title: 'Le parcours du gourmand',
    subtitle: "Jusqu'à 50€ offerts",
    step1: {
      order: '1ère COMMANDE',
      benefit: '15€ offerts',
      condition: 'Dès 60€ avec le code %{code}',
    },
    step2: {
      order: '2ème COMMANDE',
      benefit: '10€ offerts',
      condition: 'Dès 60€ - Valable pendant 7 jours',
    },
    step3: {
      order: '3ème COMMANDE',
      benefit: '5€ offerts',
      condition: 'Dès 60€ - Valable pendant 7 jours',
    },
    step4: {
      order: '4ème COMMANDE',
      benefit: '20€ offerts',
      condition: 'Dès 60€ - Un code de 5€ valable 4 fois - Valable pendant 70 jours',
    },
  },
  'engagements': {
    'title': 'Engagés pour le goût au prix juste',
    'engagement-1': 'Promos de la semaine',
    'engagement-2': 'Grand plaisir, petits prix',
    'engagement-3': 'Tous les étals',
  },
  'process-steps': {
    'title': 'Vos courses comme au marché en 3 étapes',
    'step-1-text': "Choisissez l'adresse et l'heure de livraison",
    'step-1-subtext': 'Ou choisissez un point de retrait',
    'step-2-text': 'Faites votre marché',
    'step-2-subtext': 'Parmi plus de 2000 produits frais',
    'step-3-text': 'Vous êtes livrés 7j/7',
    'step-3-subtext': 'À domicile dans tout Paris',
  },
  'app-description': {
    title: "Gagnez du temps avec l'appli Mon Marché",
    subtitle: 'Téléchargez notre application Android et iPhone',
  },
  'app-download': {
    title: "Téléchargez l'app <br /> Mon Marché",
  },
  'testimonials': {
    title: 'Ils ont choisi Mon Marché',
    source: 'Source : ',
    one: {
      name: 'Georges',
      title: 'Vaste choix: client satisfait donc fidèle !',
      description:
        "Vaste choix, produits frais avec une tracabilité, origine française pour la grande majorité, respect des horaires de livraison sur un créneau d'une heure, livreur toujours aimable et souriant, et un service client aussi réactif qu'efficace !",
      source: 'Trustpilot',
      date: '5 juillet 2023',
    },
    two: {
      name: 'Quentin',
      title: 'Les produits sont top !!!',
      description:
        'Les produits sont de très bonne qualité. La livraison arrive toujours à l’horaire choisi. C’est frais et goûteux, je fais plein de super recettes !',
      source: 'Trustpilot',
      date: '24 mars 2023',
    },
    three: {
      name: 'Myriam',
      title: 'Super expérience!',
      description:
        'Super expérience je suis maintenant cliente fidèle depuis 6 mois. Des produits frais de grande qualité une expérience très facile sur l’application et des livreurs efficaces et adorables. Merci à toute l’équipe mon marché!',
      source: 'Trustpilot',
      date: '18 mai 2023',
    },
  },
  'pickup-button-label': 'Choisir un point de retrait',
  'pickup-button-label-short': 'Point de retrait',
  'later-label': 'Plus tard',
  'later-label-long': 'Indiquer mon adresse plus tard',
  'add-address': {
    'catch-phrase': 'Faites-vous livrer à domicile !',
    'title': 'Saisissez votre adresse',
  },
  'alt-label-account-button': 'Mon compte',
  'alt-logo-gf': 'Logo de Grand Frais',
  'reassurance': {
    'text-1': 'Faites vos courses en moins de <span>30 minutes à prix mini</span>',
    'text-2': 'Des produits frais en direct de <span>700 producteurs français</span>',
    'text-3': '<span>Livraison gratuite</span><br /> à partir de <span>60€</span>',
  },
  'promoText': {
    'v3': '<span>15€ offerts dès 60€</span> d’achats pour votre première<br /> commande avec le code',
    'v3-mobile':
      '<span>15€ offerts dès 60€</span> d’achats pour votre première commande avec le code',
    'v4': 'Jusqu’à <span>50€ offerts</span> sur vos premières commandes<br/> avec le code',
    'v4-mobile': 'Jusqu’à <span>50€ offerts</span> sur vos premières commandes avec le code',
  },
  'link-to-signup': "J'en profite !",
  'tooltip-copy-promocode': 'Code promo ajouté au presse-papier !',
};
