import { css } from 'styled-components';

export const notificationStyle = css`
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: ${({ theme }) => theme.palette.common.ERRORS};
`;

export const hideScrollbar = css`
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;
