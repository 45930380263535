import { useFeatureFlag } from '~/src/common/services/FeatureFlag/client';
import I18n from '~/src/common/services/I18n';
import { useNavigation } from '~/src/queries/api-ecom/generated/api-ecom';

import FamilyList from '../FamilyList/FamilyList';
import { NavigationSource, NavigationTrackingProvider } from '../tracking';

import * as NewStyles from './layout';
import * as OldStyles from './layout-old';

type Props = {
  source?: NavigationSource;
  noTitle?: boolean;
  hideOnMobile?: boolean;
};

const Navigation = ({ source, noTitle, hideOnMobile }: Props) => {
  const isFamiliesVisibleOnMobile = useFeatureFlag('show-families-home-mobile');
  const { data } = useNavigation();

  const isVertical = source === 'Burger menu';

  const S = isFamiliesVisibleOnMobile ? { ...NewStyles } : { ...OldStyles };

  return (
    <NavigationTrackingProvider navigation={data} source={source}>
      <S.Container $hideOnMobile={hideOnMobile}>
        {noTitle ? null : <S.Title>{I18n.t('common.family-header')}</S.Title>}
        {data == null ? (
          <S.Placeholder />
        ) : (
          <FamilyList vertical={isFamiliesVisibleOnMobile && isVertical} families={data.families} />
        )}
      </S.Container>
    </NavigationTrackingProvider>
  );
};

export default Navigation;
