import { useScreenSize } from '~/src/common/hooks/useScreenSize';
import HeaderDeliveryMobile from '~/src/screens/App/Header/Navigation/HeaderDeliveryMobile';

import CenterContent from './CenterContent';
import HeaderButtons from './HeaderButtons';
import useSearchAction from './hooks/useSearchAction';
import * as S from './layout';
import LeftContent from './LeftContent';

const Navigation = () => {
  const { isSearchOpen, searchContainerRef, handleSearchClick, setSearchOpened } =
    useSearchAction();
  const { isLargeScreen } = useScreenSize();

  return (
    <S.StickyContainer ref={searchContainerRef}>
      <S.CommonContainer>
        <S.LeftContainer>
          <LeftContent />
        </S.LeftContainer>

        <S.CenterContainer>
          <CenterContent isSearchOpen={isSearchOpen} setSearchOpened={setSearchOpened} />
        </S.CenterContainer>

        <S.RightContainer>
          <HeaderButtons onSearchClick={handleSearchClick} />
        </S.RightContainer>
      </S.CommonContainer>

      {!isLargeScreen ? <HeaderDeliveryMobile /> : null}
    </S.StickyContainer>
  );
};

export default Navigation;
