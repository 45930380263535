// eslint-disable-next-line no-restricted-imports
import toast, { Toaster, ToastOptions } from 'react-hot-toast';

import { useShouldDisplayWidthThreshold } from '~/src/common/hooks/useDom';
import Notification from '~/src/common/services/Toaster/Toast';
import { breakpoints } from '~/src/styles-constants';

import { NotificationWithIcon, NotificationWithImage } from './Toast/Toast';

export type notifyProps =
  | Omit<NotificationWithIcon, 'visible' | 'id'>
  | Omit<NotificationWithImage, 'visible' | 'id'>;

const notify = (notifyProps: notifyProps, hotToastOptions?: ToastOptions) => {
  toast.remove();

  toast.custom(
    t => <Notification {...notifyProps} visible={t.visible} id={t.id} />,
    hotToastOptions,
  );
};

const POS_S = { top: 16 };
const POS_M = { top: 73, bottom: 81 };
const POS_L = { top: 133, bottom: 81 };

export const ToasterContainer = () => {
  const isSmall = !useShouldDisplayWidthThreshold(breakpoints.S);
  const isLarge = !useShouldDisplayWidthThreshold(breakpoints.XL);
  const containerPositionStyle = (isSmall && POS_S) || (isLarge && POS_L) || POS_M;

  return (
    <Toaster
      containerStyle={containerPositionStyle}
      position="top-center"
      toastOptions={{ custom: { duration: 5_000 } }}
    />
  );
};

export default {
  notify,
};
