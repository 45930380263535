import Script from 'next/script';

const AppsflyerSmartBanner = () => (
  <Script id="script-appsflyerbanner" strategy="afterInteractive">
    {`
!function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){(t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF","banners",{banners: {key: "${process.env.NEXT_PUBLIC_APPSFLYER_WEB_KEY}"}});
AF('banners', 'showBanner', { bannerContainerQuery: "#smartbanner"});
    `}
  </Script>
);

export default AppsflyerSmartBanner;
