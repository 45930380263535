import styled from 'styled-components';

import Icon from '~/src/common/components/Icon';
import Link from '~/src/common/components/Link';
import { getMediaQuery } from '~/src/common/utils/style';
import { breakpoints, zIndex } from '~/src/styles-constants';

export const Container = styled.div<{ $isHidden?: boolean }>`
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'flex')};
  flex-direction: column;
  padding: 8px 0;
  margin: 0;
  gap: 8px;

  position: static;
  inset: 100% -1px auto;
  z-index: ${zIndex.familiesMenu};

  overflow: hidden;
  background: ${({ theme }) => theme.palette.common.WHITE};
  box-shadow: none;

  @media ${getMediaQuery(breakpoints.XL, null)} {
    display: none;
  }
`;

export const Item = styled(Link)`
  margin: 0;
  padding: 0;

  display: flex;
  align-items: center;
  gap: 8px;
`;

/** Image de la catégorie */
export const Image = styled.img`
  width: 60px;
  height: 60px;
  flex: 0 0 60px;
  object-fit: cover;
  border-radius: 4px;
`;

/** Le nom de la catégorie */
export const Name = styled.strong`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette.text};
`;

/** Flèche de navigation */
export const Arrow = styled(Icon)`
  flex: 0 0 auto;
  font-size: 16px;
  margin-left: auto;
  color: ${({ theme }) => theme.palette.text};
`;
