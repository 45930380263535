import styled from 'styled-components';

import { getMediaQuery } from '~/src/common/utils/style';
import { breakpoints } from '~/src/styles-constants';

import { Title } from '../FamilyCard/layout';

/** Les élément doivent avoir la même taille, d'où le flex et le width */
export const Container = styled.div<{ $vertical?: boolean }>`
  width: 1px;
  flex: 1 0 160px;
  scroll-margin-top: 96px;

  @media ${getMediaQuery(breakpoints.XL, null)} {
    flex: 1 1 1px;
  }

  /* Mobile menu: Largeur et hauteur fixe */
  ${({ $vertical }) =>
    $vertical
      ? `@media ${getMediaQuery(0, breakpoints.XL)} {
        width: auto;
        height: auto;
        flex: 0 0 auto;
      }`
      : ''}
`;

export const FamilyName = styled(Title)`
  padding: 0;
  font-size: 20px;
  text-align: left;
`;
