export default {
  'title': 'Commande du %{date}',
  'other-categories-title': 'Autres',
  'button-label': 'Ajouter tous les produits à mon panier',
  'message': '%{count} produit ajouté à votre panier',
  'message_plural': '%{count} produits ajoutés à votre panier',
  'action-button-label': 'Voir mon panier',
  'error':
    'Certains produits ne sont pas disponibles pour le créneau sélectionné, votre liste a été mise à jour, veuillez réessayer',
};
