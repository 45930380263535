import { useEffect } from 'react';

import { Metric, onCLS, onFCP, onFID, onINP, onLCP, onTTFB } from 'web-vitals';

import { noop } from '~/src/common/utils/function';
import { isLocal } from '~/src/env-constants';
import { VitalsBody } from '~/src/queries/api-ecom/generated/api-ecom.schemas';

/**
 * Principe général :
 *
 * on souhaite suivre des indicateurs sur les performances du site, pour de vrais utilisateurs, sur le
 * long terme.
 *
 *  - on utilise les web vitals google, et on les mesure avec le package web-vitals (qui est fiable et mis à jour avec les changements de navigateurs)
 *  - on stocke uniquement les infos nécessaires, en particulier pas de PII et uniquement les données nécessaires aux indicateurs
 *  - on fait un minimum de traitement (comme ça, en cas de souci, on peut refaire le traitement a posteriori coté BDD)
 *  - on stocke les données dans une base BigQuery, ce qui permet de les conserver à long terme.
 *
 * Sur le fonctionnement général :
 *
 * - On initialise le package web-vitals dans _app.tsx
 * - Lorsque des vitals doivent être reportées, elles sont envoyées une par une avec sendBeacon plutôt que fetch (ce qui permet de ne pas
 *   perturber les autres requêtes + d'envoyer des données à la fermeture de la page, ce qui concerne notamment le CLS)
 * - l'API reçoit les données et les écrit dans BigQuery
 */
export const useWebVitals = () => {
  useEffect(() => {
    if (isLocal) return;

    const url = window.location.href;

    const report: (arg: Metric) => void = arg => {
      const body: VitalsBody = {
        data: {
          type: 'vitals',

          id: arg.id,
          name: arg.name,
          value: arg.value,
          navigationType: arg.navigationType,
          url,
        },
      };

      if (navigator instanceof Navigator) {
        try {
          navigator.sendBeacon(
            '/api/vitals',
            new Blob([JSON.stringify(body)], { type: 'application/json' }),
          );
        } catch {
          noop();
        }
      }
    };

    onCLS(report);
    onFID(report);
    onLCP(report);
    onFCP(report);
    onTTFB(report);
    onINP(report);
  }, []);
};
