export default {
  'default-title': 'Une erreur est survenue',
  // Timeout etc..
  'network': {
    title: 'Une erreur est survenue !',
    description: 'Une erreur est survenue, veuillez vérifier votre connexion Internet',
  },
  // >= 500
  'server': {
    title: 'Une erreur est survenue !',
    description:
      "Une erreur est survenue, l'information a été envoyée à nos équipes qui vont investiguer le problème",
  },
  'E_08_0004': 'Votre panier ne contient pas d’article',
  'E_ECOM_02_0002': 'Votre session a expiré, veuillez vous reconnecter',
};
