import { logger } from '~/src/common/services/Logger';
import { withResolvers } from '~/src/common/utils/promise';

import { Braze } from './types';

// Utilisation d'une promise déferrée pour Braze
const deferBraze = withResolvers<Braze>();

// Création d'un wrapper permettant d'accèder à Braze une fois sa promesse résolue/rejetée
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Pour l'inférence
const withBraze = <T extends (...args: any[]) => any>(cb: (b: Braze) => T) => {
  return async (...args: Parameters<T>): Promise<ReturnType<T> | null> => {
    if (typeof window !== 'undefined' && window.braze) deferBraze.resolve(window.braze);
    const braze = await deferBraze.promise.catch(() => null);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- Idem plus haut
    return braze && cb ? cb(braze)(...args) : null;
  };
};

// Mise à jour de la disponibilité de Braze :
// - Résolution immédiate si Braze est disponible directement dans `window.braze`
// - Surveillance de `window.braze` et résolution, avec délai pour init, à la disponibilité
// - Rejection après un timeout de 30s, afin d'éviter les fuites mémoire si Braze ne charge jamais
if (typeof window !== 'undefined') {
  if (window.braze) deferBraze.resolve(window.braze);
  else {
    Object.defineProperty(window, 'braze', {
      configurable: true,
      set: (value: Braze) => {
        Object.defineProperty(window, 'braze', { value });
        setTimeout(() => deferBraze.resolve(value), 10);
      },
    });
    setTimeout(() => {
      if (window.braze) return;
      const msg = 'Braze not available';
      deferBraze.reject(msg);
      logger.warning(msg);
    }, 30_000);
  }
}

// Export du service
export default {
  changeUser: withBraze(braze => braze.changeUser),
  logCustomEvent: withBraze(braze => braze.logCustomEvent),
  requestPushPermission: withBraze(braze => braze.requestPushPermission),
  getCachedContentCards: withBraze(braze => braze.getCachedContentCards),
  subscribeToContentCardsUpdates: withBraze(braze => braze.subscribeToContentCardsUpdates),
  removeSubscription: withBraze(braze => braze.removeSubscription),
  logCardImpressions: withBraze(braze => braze.logCardImpressions),
  requestContentCardsRefresh: withBraze(braze => braze.requestContentCardsRefresh),
};
