import { Dispatch, SetStateAction } from 'react';

import Link from '~/src/common/components/Link';
import SearchBar from '~/src/common/components/Search/Bar';
import { useScreenSize } from '~/src/common/hooks/useScreenSize';
import I18n from '~/src/common/services/I18n';

import useNavigationActions from './hooks/useNavigationActions';
import * as S from './layout';

interface Props {
  isSearchOpen: boolean;
  setSearchOpened: Dispatch<SetStateAction<boolean>>;
}

const CenterContent = ({ isSearchOpen, setSearchOpened }: Props) => {
  const { isLargeScreen } = useScreenSize();
  const { handleLogoClick } = useNavigationActions();

  return (
    <>
      <SearchBar active={isSearchOpen} setActive={setSearchOpened} />

      {!isLargeScreen ? (
        <Link href="/" onClick={handleLogoClick} aria-label={I18n.t('navigation.brand')}>
          <S.StyledLogo />
        </Link>
      ) : null}
    </>
  );
};

export default CenterContent;
