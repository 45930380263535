import { useEffect } from 'react';

import { useStable } from '~/src/common/hooks/useStable';

export const useKeyboardKey = (
  key: string,
  onKeyPress: (event: KeyboardEvent) => void,
  enabled = true,
) => {
  const stableHandler = useStable(onKeyPress);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.key !== key) return;
      if (!enabled) return;

      stableHandler(event);
    };

    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [onKeyPress, key, enabled, stableHandler]);
};
