import styled, { css } from 'styled-components';

import LogoIcon from '~/src/common/assets/logo.svg';
import Button from '~/src/common/components/Button';
import { notificationStyle } from '~/src/common/themes/main/shared-styles';
import { NAV_HEIGHT, media, zIndex } from '~/src/styles-constants';

export const StickyContainer = styled.header`
  width: 100%;
  z-index: ${zIndex.navigationHeader};

  display: flex;
  flex-direction: column;

  position: sticky;
  top: 0;

  background-color: ${({ theme }) => theme.palette.primary};

  ${media.XL`
    padding: 0 56px;
  `}
`;

export const CommonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 0 16px;

  height: ${NAV_HEIGHT}px;
`;

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const CenterContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.XL`
    display: block;
  `}
`;

export const RightContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  ${media.XL`
    gap: 24px;
  `}
`;

export const TransparentButton = styled.button<{
  $hasNotification?: boolean;
}>`
  background: none;
  border: none;
  cursor: pointer;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 48px;

  padding: 0;

  ${media.XL`
    gap: 4px;
    padding: 4px 0;

    width: 72px;

    &:hover {
      background-color: ${({ theme }) => theme.palette.common.PRIMARY_HOVER};
    }

    > span:last-child {
      font-size: 11px;
      color: ${({ theme }) => theme.palette.common.WHITE};
    }
  `}

  ${({ $hasNotification }) =>
    $hasNotification
      ? css`
          &::after {
            ${notificationStyle};
            content: '';
            position: absolute;
            top: 4px;
            right: 16px;
          }
        `
      : null}
`;

export const CartIconContainer = styled.div`
  position: relative;

  height: 24px;
`;

export const SizeBullet = styled.span`
  display: inline-block;
  background-color: ${({ theme }) => theme.palette.common.DARK_LABEL};
  color: ${({ theme }) => theme.palette.common.BACKGROUND_1};
  padding-top: 4px;
  text-align: center;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  font-size: 9px;
  line-height: 8px;
  position: absolute;
  top: -8px;
  right: -12px;

  ${media.XL`
    top: 0;
    right: -20px;
  `}
`;

export const StyledLogo = styled(LogoIcon)`
  max-width: 117px;
  display: block;
  height: inherit;
`;

export const BurgerMenuButton = styled(Button)`
  translate: -8px 0;

  [role='img'] {
    font-size: 24px;
    color: ${({ theme }) => theme.palette.common.WHITE};
  }
`;
