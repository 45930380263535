import { logger } from '~/src/common/services/Logger';

import { IdentifyProperties, ampli } from './generated';
import { EventProperties, EventName } from './types';

const init = () => {
  if (ampli.isLoaded) return;
  ampli.load({
    client: {
      apiKey: process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY,
      configuration: {
        defaultTracking: {
          // On souhaite tracker les UTM dans les user properties, ils seront mis à jour à chaque nouvelle session
          attribution: true,
          pageViews: false,
          sessions: false,
          formInteractions: false,
          fileDownloads: false,
        },
      },
    },
  });
};

const setUserId = (userId: string | undefined) => {
  init();
  ampli.client.setUserId(userId);
  if (typeof userId === 'string' && userId.length > 16) {
    logger.warning('Invalid Amplitude UserID', { invalidUserID: userId });
  }
};

const setUserProperties = (userProperties: IdentifyProperties) => {
  init();
  ampli.identify(undefined, userProperties);
};

const sendEvent = <T extends EventName>(eventName: T, eventProperties?: EventProperties<T>) => {
  init();
  ampli.track({ event_type: eventName, event_properties: eventProperties });
};

const Tracker = { setUserId, setUserProperties, sendEvent };

export default Tracker;
