import { useRouter } from 'next/router';

import { isHomePathname } from '~/src/common/utils/home';
import { useGetCart, useGetCartLight } from '~/src/queries/api-ecom/generated/api-ecom';
import { DeliveryItems } from '~/src/screens/App/Header/Navigation/DeliveryItems';
import useDeliveryActions from '~/src/screens/App/Header/Navigation/hooks/useDeliveryActions';

import * as S from './layout';

// Routes où on veut afficher les champs de saisie d'adresse de livraison lorsque l'utilisateur n'a pas d'adresse (hormis la home)
const ROUTES_WITH_HEADER_MOBILE = ['/produit/[slug]', '/recette/[slug]'];

const HeaderDeliveryMobile = () => {
  const { pathname, route } = useRouter();

  const { timeSlot, handleDeliveryAddressClick, handleDeliverySlotClick } = useDeliveryActions();
  const { data: cart } = useGetCart();
  const { data: cartLight } = useGetCartLight();

  const hasAddress = cart?.delivery != null || cartLight?.delivery != null;

  const displayHeaderMobileContent =
    isHomePathname(pathname) || (ROUTES_WITH_HEADER_MOBILE.includes(route) && !hasAddress);

  if (!displayHeaderMobileContent) return null;

  return (
    <S.Container>
      <DeliveryItems
        hasAddress={hasAddress}
        timeSlot={timeSlot}
        onDeliveryAddressClick={handleDeliveryAddressClick}
        onDeliverySlotClick={handleDeliverySlotClick}
      />
    </S.Container>
  );
};

export default HeaderDeliveryMobile;
