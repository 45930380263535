import { ProductListItemProduct } from '~/src/common/components/ProductListItem/types';
import I18n from '~/src/common/services/I18n';
import { logger } from '~/src/common/services/Logger';
import Tracker, { ProductExtraProperties } from '~/src/common/services/Tracker';
import { CartProduct } from '~/src/common/typings/cart';
import { isBoolean, isNumber, isString } from '~/src/common/utils/guards';
import { isProduct, isRecipe } from '~/src/typings/products/guards';
import { Product } from '~/src/typings/products/types';
import { CatalogItem } from '~/src/typings/products/types';

import { HavingType, HomeProduct } from '../typings/product';

/** Type minimaliste utilisé uniquement dans ce fichier */
type MiniProduct = {
  id: string;
  sku: string;
  enabled?: boolean | undefined;
  itemPrice?: number | undefined;
  availableQuantity?: number | undefined;
  hasSubstituteProducts?: boolean | undefined;
};

export const SPECIFIC_TAG_ATTRIBUTE_KEY = 'specific-tag';

/** Extrait l'ID canonique d'un produit depuis un ID */
export const getCanonicalIdFromId = (id: HomeProduct['id']) => id.split('$')[0];

/** Identifie si deux entités représentent le même produit */
export const isSameProduct = (
  product1: { sku: string; id: string },
  product2: { sku: string; id: string },
) =>
  product1.sku !== '' && product2.sku !== ''
    ? product1.sku === product2.sku
    : getCanonicalIdFromId(product1.id) === getCanonicalIdFromId(product2.id);

/** Retourne un booleen indiquant si le produit est disponible à la vente */
export const isAvailable = (item: MiniProduct) => {
  const hasStock = item.availableQuantity != null && item.availableQuantity > 0;
  const hasPrice = item.itemPrice != null;
  const isEnabled = item.enabled !== false;
  return hasStock && hasPrice && isEnabled;
};

/** Retourne true si le produit possede des produits similaires */
export const hasSubstitutes = (product: MiniProduct) => {
  return product.hasSubstituteProducts === true;
};

/** Sépare une liste de produit en deux listes, ceux disponible à l'achat, et les autres */
export const groupByAvailability = <T extends MiniProduct>(products: T[]) => {
  type Data = { id: string; sku: string; stock: number; index: number };
  const availableProducts: Data[] = [];
  const unavailableProducts: Data[] = [];
  products.forEach((product, index) => {
    const id = getCanonicalIdFromId(product.id);
    const stock = product.availableQuantity ?? 0;
    const item = { id, sku: product.sku, stock, index };
    if (isAvailable(product)) availableProducts.push(item);
    else unavailableProducts.push(item);
  });
  return { availableProducts, unavailableProducts };
};

/**
 * @param products
 * @return les propriétés communes aux évènements liés aux produits
 * in stock products : nombre de produits disponibles
 * out of stock products : nombre de produits indisponibles
 * similar products activated : nombre de produits indisponibles et avec des produits similaires
 * out of stock rate : nombre de produits indisponibles / nombre de produits total
 * similar products activated rate : nombre de produits indisponibles avec produits similaires / nombre de produits indisponibles total
 */
export const getCommonProductsEventProperties = <T extends MiniProduct>(products: T[] = []) => {
  const productCount = products.length;
  const { availableProducts, unavailableProducts } = groupByAvailability(products);
  const unavailableWithSubstitutes = products.filter(p => !isAvailable(p) && hasSubstitutes(p));
  const getRate = (v: number) => (productCount ? Math.floor((v / productCount) * 100) : undefined);

  return {
    // Liste des produits disponibles/indisponibles
    'available products': availableProducts,
    'unavailable products': unavailableProducts,
    // Nombre de produits disponibles/indisponibles
    'in stock products': availableProducts.length,
    'out of stock products': unavailableProducts.length,
    'out of stock rate': getRate(unavailableProducts.length),
    // Nombre de produits indisponibles, mais avec des remplacements
    'similar products activated': unavailableWithSubstitutes.length,
    'similar products activated rate': getRate(unavailableWithSubstitutes.length),
  };
};

export const hasInsufficientQuantity = (product: CartProduct) =>
  product.actions?.some(
    a => a.type === 'CHANGE_INSUFFICIENT_AVAILABLE_QUANTITY' && a.availableQuantity > 0,
  );

export const hasUpdatedInfoActions = (product: CartProduct) =>
  product.actions?.some(
    a =>
      a.type === 'CHANGE_VARYING_ATTRIBUTE' ||
      a.type === 'CHANGE_PRICE' ||
      a.type === 'CHANGE_PRODUCT_NAME' ||
      a.type === 'CHANGE_ATTRIBUTE',
  );

export const isProductUnavailable = (product: CartProduct) => {
  const { actions } = product;

  if (!actions) return false;

  return (
    actions?.filter(
      (a: NonNullable<CartProduct['actions']>[number]) =>
        a.type === 'CHANGE_NO_LONGER_FOR_SALE' ||
        a.type === 'CHANGE_OUT_OF_STOCK' ||
        (a.type === 'CHANGE_INSUFFICIENT_AVAILABLE_QUANTITY' && a.availableQuantity === 0),
    ).length > 0
  );
};

/**
 * Envoie un event "click card <product | recipe>" event ; vérifiant au préalable le type de l'item
 *
 * @param _items liste d'items (produits/recettes...) contenant item
 * @param item carte sélectionnée
 * @param extraEventProperties EventProperties à ajouter (/!\ peut écraser les properties de base)
 */
export const sendCardEventClick = (
  _items: { type?: string }[] | undefined,
  item: CatalogItem,
  extraEventProperties: ProductExtraProperties = {},
) => {
  const items = _items ?? [];
  const countByType = (type: HavingType<typeof item>['type']) =>
    items.filter(i => 'type' in i && i.type === type).length;

  const baseEvent = {
    'number of cards in the list': items.length,
    'card position in the list': item.indexes.global,
    ...extraEventProperties,
  };

  if (isProduct(item)) {
    Tracker.sendEvent('click card product', {
      'product id': item.canonicalId,
      'product name': item.name,
      'product position in the list': item.indexes.byType,
      'number of products in the list': countByType(item.type),
      ...baseEvent,
    });
  } else if (isRecipe(item)) {
    Tracker.sendEvent('click card recipe', {
      'recipe id': item.id,
      'recipe name': item.name,
      'recipe position in the list': item.indexes.byType,
      'number of recipes in the list': countByType(item.type),
      ...baseEvent,
    });
  }
};

/**
 * Renvoie la valeur à afficher de l'attribut d'un produit
 *
 * @param attribute Objet contenant la valeur et l'unité de la valeur de l'attribut
 * @return La valeur de l'attribut à afficher
 */
export const getAttributeValue = (attribute: { value: unknown; valueUnit?: string }) => {
  const { value, valueUnit } = attribute;

  if (isBoolean(value)) {
    return I18n.t(`common.attributes.${String(value)}`);
  }

  if (Array.isArray(value) && value.length === 0) {
    return I18n.t('common.attributes.undefined');
  }

  if (Array.isArray(value) && value.every(i => typeof i === 'string')) {
    return value.join(', ');
  }

  if (isString(value) && ['true', 'false', 'N/A'].includes(value)) {
    return I18n.t(`common.attributes.${value}`);
  }

  if (value === '' || value == null) {
    return I18n.t('common.attributes.undefined');
  }

  if (isString(value) || isNumber(value)) {
    return `${value}${valueUnit && !String(value).includes(valueUnit) ? valueUnit : ''}`;
  }

  logger.error("La valeur d'un attribut de produit ne peut être affichée", { attribute });

  return '';
};

export type ProductPromoWithBulkDiscount = Exclude<Product['promo'], undefined> & {
  // On n'utilise pas l'optionnel pour etre sur d'avoir utilise la fonction de conversion, sinon ts laisse passer
  discountPerBulk: number | undefined;
};

export const getProductPromoWithBulkDiscount = (
  product: Pick<Product | ProductListItemProduct, 'promo' | 'pricing'>,
): ProductPromoWithBulkDiscount | undefined => {
  if (
    product.pricing?.sellPrices.perWeightUnit?.main !== true ||
    product.promo?.conditions?.nthQuantity == null ||
    product.promo.conditions.type === 'PERCENT' ||
    product.pricing.sellPrices.perWeightUnit.net == null ||
    product.pricing.sellPrices.perWeightUnit.value == null
  )
    return product.promo ? { ...product.promo, discountPerBulk: undefined } : undefined;

  const priceDiffPerKG =
    product.pricing.sellPrices.perWeightUnit.net - product.promo.conditions.value;

  return {
    ...product.promo,
    discountPerBulk: priceDiffPerKG * product.pricing.sellPrices.perWeightUnit.value,
  };
};

export const getCustomTag = (product: Pick<Product, 'attributes'>) => {
  const customTagAttr = product.attributes.find(({ key }) => key === SPECIFIC_TAG_ATTRIBUTE_KEY);
  const customTag = typeof customTagAttr?.value === 'string' ? customTagAttr.value : undefined;
  return customTag?.trim() || null;
};

export const getProductLabelImages = (product: Pick<Product, 'labels'>) => {
  return product.labels
    .map(item => ({ id: item.id, label: item.label, image: item.images[0] }))
    .filter(item => item.image != null);
};
