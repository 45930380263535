import { CurrentOrders200ItemsItem } from '~/src/queries/api-ecom/generated/api-ecom.schemas';

export const SMART_BANNER_ID = 'smartbanner';

export function isDelivery(delivery?: Pick<CurrentOrders200ItemsItem['delivery'], 'deliveryZone'>) {
  return delivery?.deliveryZone?.type === 'delivery';
}

function comparator(a: CurrentOrders200ItemsItem, b: CurrentOrders200ItemsItem) {
  if (a.delivery.timeSlot.from < b.delivery.timeSlot.from) return a;
  if (b.delivery.timeSlot.from < a.delivery.timeSlot.from) return b;
  return isDelivery(a.delivery) ? a : b;
}

export function getEarliestOrder(orders: CurrentOrders200ItemsItem[]) {
  if (orders.length === 0) return null;

  return orders.reduce(
    (earliest, order) => (comparator(order, earliest) ? order : earliest),
    orders[0],
  );
}

export function isNextOrderDelivery(orders: CurrentOrders200ItemsItem[]) {
  const next = getEarliestOrder(orders);
  return next ? isDelivery(next.delivery) : false;
}
