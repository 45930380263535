import { useEffect, useMemo, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { debounce } from 'debounce';

import { DELIVERY_BAR_TREESHOLD } from '~/src/common/components/DeliveryProgressBar/constants';
import usePrevious from '~/src/common/hooks/usePrevious';
import { CartDelivery } from '~/src/common/typings/cart';
import { getRecompute2QueryKey } from '~/src/queries/api-ecom/generated/api-ecom';
import { Recompute2200 } from '~/src/queries/api-ecom/generated/api-ecom.schemas';

const DURATION_DISPLAY_BANNER = 3600;

export const useTimerDisplayDeliveryBanner = () => {
  const queryClient = useQueryClient();
  const cart = queryClient.getQueryData<Recompute2200>(getRecompute2QueryKey());
  const prevCartPrice = usePrevious(cart?.price?.quotation.net);
  const prevMinOrderAmountReached = usePrevious(cart?.minOrderAmountReached);
  const currentCartPrice = cart?.price?.quotation.net ?? 0;
  const [show, setShow] = useState(false);

  const hideBannerDebounce = useMemo(() => {
    return debounce(() => setShow(false), DURATION_DISPLAY_BANNER);
  }, []);

  useEffect(() => {
    let hasStepChanged = false;
    let isInThreshold = false;
    let shouldDisplayBanner = false;
    const sortedDeliveryPrices = cart?.delivery?.deliveryPrices
      ? [...cart.delivery.deliveryPrices].sort(
          (priceA, priceB) => priceA.minCartNetPrice - priceB.minCartNetPrice,
        )
      : [];

    // Tant que la jauge est affichée et que le panier est modifié, on affiche la jauge
    const keepDisplayingBanner = show && prevCartPrice !== currentCartPrice;

    // Checks basiques pour voir si on peut afficher la jauge
    const areChecksToDisplayBannerOK =
      prevCartPrice !== undefined &&
      currentCartPrice !== prevCartPrice &&
      cart?.minOrderAmountReached &&
      sortedDeliveryPrices &&
      sortedDeliveryPrices.length > 1;

    // Voir si les conditions de changement d'étape et de montant permettent d'afficher la jauge
    if (areChecksToDisplayBannerOK) {
      const {
        current: numberOfReachedSteps,
        previous: previousNumberOfReachedSteps,
        unReachedStep: firstUnReachedStep,
      } = sortedDeliveryPrices.reduce(
        (
          acc: {
            current: number;
            previous: number;
            unReachedStep: CartDelivery['deliveryPrices'][number] | null;
          },
          step: CartDelivery['deliveryPrices'][number],
        ) => {
          const unReachedStep =
            step.minCartNetPrice > currentCartPrice && !acc.unReachedStep
              ? step
              : acc.unReachedStep;
          const previous =
            prevCartPrice && step.minCartNetPrice <= prevCartPrice
              ? acc.previous + 1
              : acc.previous;
          const current = step.minCartNetPrice <= currentCartPrice ? acc.current + 1 : acc.current;

          return { current, previous, unReachedStep };
        },
        {
          current: 0,
          previous: 0,
          unReachedStep: null,
        },
      );

      hasStepChanged = numberOfReachedSteps !== previousNumberOfReachedSteps;

      isInThreshold = Boolean(
        firstUnReachedStep?.minCartNetPrice &&
          firstUnReachedStep.minCartNetPrice - currentCartPrice <= DELIVERY_BAR_TREESHOLD,
      );

      shouldDisplayBanner = hasStepChanged || isInThreshold || !prevMinOrderAmountReached;
    }

    if (shouldDisplayBanner || keepDisplayingBanner) {
      setShow(true);
      hideBannerDebounce();
    }
  }, [
    cart?.delivery?.deliveryPrices,
    currentCartPrice,
    prevCartPrice,
    prevMinOrderAmountReached,
    cart?.minOrderAmountReached,
    hideBannerDebounce,
    show,
  ]);

  return show;
};
