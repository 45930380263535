import { isGreenTimeslot } from '~/src/common/components/DeliverySlots/utils';
import dayjs from '~/src/common/services/Date';
import { DeliverySlot } from '~/src/common/typings/cart';
import { last } from '~/src/common/utils/array';
import { getTomorrowTimestamp } from '~/src/common/utils/date';

export const countAvailableSlots = <T extends { isExpired: boolean; isFull: boolean }>(
  slots?: T[],
) => (slots ? slots.filter(s => !s.isExpired && !s.isFull).length : 0);
export const countFullSlots = <T extends { isExpired: boolean; isFull: boolean }>(slots?: T[]) =>
  slots ? slots.filter(s => !s.isExpired && s.isFull).length : 0;

export const countTotalSlots = <T extends { isExpired: boolean }>(slots?: T[]) =>
  slots ? slots.filter(s => !s.isExpired).length : 0;
export const computeFullSlotsRate = <T extends { isExpired: boolean; isFull: boolean }>(
  slots?: T[],
) => (slots ? Math.floor((countFullSlots(slots) / countTotalSlots(slots) || 0) * 100) : 0);
export const isFreeDeliveryBannerDisplayed = <
  T extends { deliveryPricesWithDeltas: { minCartNetPrice: number; shippingAmount: number }[] },
>(
  slots?: T[],
) => {
  // Slot avec le seuil de gratuité le plus élevé
  const highestFreeDeliverySlot = slots
    ? slots
        .filter(
          deliverySlot =>
            deliverySlot.deliveryPricesWithDeltas?.some(dp => dp.shippingAmount === 0) &&
            deliverySlot.deliveryPricesWithDeltas?.some(dp => dp.shippingAmount > 0),
        )
        .sort((deliverySlotA, deliverySlotB) => {
          return (
            (last(deliverySlotB.deliveryPricesWithDeltas)?.minCartNetPrice || 0) -
            (last(deliverySlotA.deliveryPricesWithDeltas)?.minCartNetPrice || 0)
          );
        })?.[0]
    : undefined;

  // On veut savoir si au moins un créneau est toujours payant
  const hasPaidSlot = slots?.some(
    ds => ds.deliveryPricesWithDeltas?.every(dp => dp.shippingAmount > 0),
  );

  return Boolean(highestFreeDeliverySlot && !hasPaidSlot);
};

/**
 * @param slots
 * @param selectedSlot
 * @return les propriétés communes aux évènements liés aux créneaux
 * - day : 'Monday, Tuesday, ...' jour de la semaine de la date de début du premier créneau
 * - shipping slot days : différence en jours entre le jour du premier slot et aujourd'hui
 * - date : date de début du premier créneau (jj/mm)
 * - full slots : nombre de créneaux complets qui ne sont pas expirés
 * - available slots : nombre de créneaux disponibles qui ne sont pas expirés
 * - total slots : nombre de créneaux total qui ne sont pas expirés
 * - full slots rate : pourcentage de créneaux disponibles parmi ceux qui ne sont pas expirés
 * - slot type : type de créneau (normal, green, ...)
 * - total green slots : nombre de créneaux éco (tag 'green')
 */
export const getCommonSlotEventProperties = (
  slots: DeliverySlot[],
  selectedSlot: Pick<DeliverySlot, 'tag' | 'from'> = slots?.[0],
) => {
  if (!slots || slots.length === 0) return undefined;

  const fromDayJs = dayjs(selectedSlot.from);
  const day = fromDayJs.format('dddd');
  const today = dayjs().set('hour', 0).set('minutes', 0);
  return {
    'day': day,
    'shipping slot days': fromDayJs.diff(today, 'day'),
    'date': fromDayJs.format('DD/MM'),
    'full slots': countFullSlots(slots),
    'available slots': countAvailableSlots(slots),
    'total slots': countTotalSlots(slots),
    'full slots rate': computeFullSlotsRate(slots),
    'slot type': selectedSlot.tag ?? ('normal' as const),
    'total green slots': slots.filter(isGreenTimeslot).length,
  };
};

export const getPreferredTimeSlotTimestamp = (minutes: number) => {
  return getTomorrowTimestamp(Math.floor(minutes / 60), minutes % 60);
};
