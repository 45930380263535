import { CartRelatedAlgo } from './types';

export default {
  algo: {
    most_sales_articles_by_customer: {
      title: 'Vous n’avez rien oublié ?',
      text: 'Voici une sélection de produits que vous avez l’habitude de commander !',
    },
    most_sales_articles: {
      title: 'Envie d’autre chose ?',
      text: 'Voici une sélection de produits que vous pourriez aimer !',
    },
    most_sales_articles_by_pim__house: {
      title: 'Un coup de propre ?',
      text: "Découvrez nos produits d'entretien et faites vos courses en un seul passage !",
    },
    most_sales_articles_by_pim__beauty: {
      title: 'Routine feel good',
      text: "Découvrez nos produits d’hygiène et faites vos courses en un clin d'œil !",
    },
    fallback: {
      title: 'Vous n’avez rien oublié ?',
      text: 'Voici une sélection de produits que vous pourriez aimer !',
    },
  } satisfies Record<CartRelatedAlgo, { title: string; text: string }>,
};
