import styled, { css } from 'styled-components';

import { getMediaQuery } from '~/src/common/utils/style';
import {
  CATALOG_SIDE_PADDING,
  CATALOG_SIDE_PADDING_V2,
} from '~/src/screens/App/CatalogLayout/layout';
import { breakpoints, media, NAV_HEIGHT, zIndex } from '~/src/styles-constants';

/**
 * Le menu des familles desktop est toujours visible, sticky sous le header
 * Caché pour les basses résolutions, mais conservé dans le DOM pour le SEO
 * Un élément est ajouté lorsque le menu est ouvert afin de masquer ce qui
 * reste visible dans l'espace entre les cartes familles et le contenu du menu
 *
 * Concernant $hideOnMobile, cette props est utilisée afin de masquer le menu
 * sur certaines pages en mobile. Aujourd'hui, partout, sauf sur la Home 🏡
 */
export const Container = styled.nav<{ $hideOnMobile?: boolean; $vertical?: boolean }>`
  position: sticky;
  top: ${NAV_HEIGHT}px;
  z-index: ${zIndex.familiesMenu};
  background: ${({ theme }) => theme.palette.common.WHITE};

  /* Mobile : Affichage dans le flux */
  ${({ $vertical, $hideOnMobile }) =>
    $vertical
      ? `@media ${getMediaQuery(0, breakpoints.XL)} {
    order: 90;
    z-index: auto;
    position: static;
    padding: 0 ${CATALOG_SIDE_PADDING}px;
    display: ${$hideOnMobile ? 'none' : 'block'}};
    }`
      : ''}
`;

/**
 * Le titre n'est affiché qu'en mobile, mais est toujours présent dans le DOM
 * Pas d'utilisation de `Hn` car le menu sera toujours avant le h1 de la page
 */
export const Title = styled.p`
  display: block;
  margin: 0;
  padding: 8px 16px 0;

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  ${media.XL`
    padding-left: calc(env(safe-area-inset-left, 0px) + ${CATALOG_SIDE_PADDING_V2}px);
    padding-right: calc(env(safe-area-inset-right, 0px) + ${CATALOG_SIDE_PADDING_V2}px);
  `}
`;

/** Placeholder desktop si les familles ne sont pas chargées */
export const Placeholder = styled.div`
  ${({ theme }) => css`
    height: 128px;
    border: solid ${theme.palette.common.WHITE};
    border-width: 8px ${CATALOG_SIDE_PADDING_V2}px;
    background: ${theme.palette.common.WHITE}
      repeating-linear-gradient(
        to left,
        ${theme.palette.common.BACKGROUND_4},
        ${theme.palette.common.BACKGROUND_4} calc((100% - 64px) / 5),
        ${theme.palette.common.TRANSPARENT} calc((100% - 64px) / 5),
        ${theme.palette.common.TRANSPARENT} calc((100% - 64px) / 5 + 16px)
      );
  `}
`;
